import { UpdateSku } from "./UpdateSku";
import { UpdateAmazonSku } from "./UpdateAmazonSku";
import { UpdateCustomSku } from "./UpdateCustomSku";
export class UpdateSkuFactory {
  static create($type) {
    if ($type === "V1.ComplianceMasterData.UpdateSku") {
      const result = new UpdateSku();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.UpdateAmazonSku") {
      const result = new UpdateAmazonSku();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.UpdateCustomSku") {
      const result = new UpdateCustomSku();
      return result;
    }
    throw new Error($type + " not found");
  }
}
