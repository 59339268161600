var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { closeIcon, fileIcon } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useEffect, useQueryState, useState } from "GlobalShared/haunted/CustomHooks";
import classNames from "classnames";
import {
  mapPairsToCustomSourceMapping
} from "./CustomDataTemplateModels";
import { notifyWarning } from "GlobalShared/helpers/errorHelper";
import { FormatTypeMapping, TAX_DESK_DATA_LABELS } from "./CustomDataTemplateHelper";
import { useSharedCustomDataTemplate } from "./useSharedCustomDataTemplates";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
const TAB_NAMES = {
  Sale: "Sale",
  Purchase: "Purchase"
};
export const TAB_QUERY_PREFIX = "tab";
export function useSeparatedFileConfiguration(props) {
  var _a, _b, _c, _d;
  const isFieldRequired = (fieldName) => {
    var _a2, _b2;
    return selectedTab === "Sale" ? (_a2 = fieldMetadata.saleFieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _a2.IsRequired : (_b2 = fieldMetadata.purchaseFieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _b2.IsRequired;
  };
  const isEveryRequiredDropFieldSet = (pairs) => !Array.from(pairs.keys()).some((key) => {
    var _a2;
    return isFieldRequired(key) && !((_a2 = pairs.get(key)) == null ? void 0 : _a2.dragField);
  });
  const callbackForMappingErrorHandler = () => __async(this, null, function* () {
    yield props.getAndSetGenerationConfiguration();
    if (selectedTab === "Sale" && isEveryRequiredDropFieldSet(purchasePairs) || selectedTab === "Purchase" && isEveryRequiredDropFieldSet(salePairs))
      return;
    changeTabAfterMappingSave();
  });
  const saveCurrentMapping = () => __async(this, null, function* () {
    setIsValidated(true);
    if (!isEveryRequiredDropFieldSet(currentPairs))
      return;
    const mapping = mapPairsToCustomSourceMapping(currentPairs);
    if (selectedTab === "Sale") {
      yield props.updateCustomSourceSeparateMapping(mapping, props.loader, "Sale", callbackForMappingErrorHandler);
      setIsSaleMappingChangedAfterSave(false);
    } else {
      yield props.updateCustomSourceSeparateMapping(mapping, props.loader, "Purchase", callbackForMappingErrorHandler);
      setIsPurchaseMappingChangedAfterSave(false);
    }
  });
  const handleUploadTransactionDataButtonClick = (e) => __async(this, null, function* () {
    setIsFileUploading(true);
    yield props.updateTemplateReference(selectedTab === "Sale" ? "Sale" : "Purchase", e.detail.files[0]);
    setUploadedFile(e.detail.files[0]);
    setIsFileUploading(false);
    if (selectedTab === "Sale") {
      setSalePairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
      setCurrentPairs(new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
    } else {
      setPurchasePairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
      setCurrentPairs(new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
    }
  });
  const getMappedPairs = (mapping, tab) => {
    var _a2;
    if (!mapping && tab === "Sale")
      return new Map(fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0]));
    if (!mapping && tab === "Purchase")
      return new Map(fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0]));
    const newPairs = /* @__PURE__ */ new Map();
    const dropfieldNames = tab === "Sale" ? fieldMetadata.saleFieldMetadata.map((field) => field.Name) : fieldMetadata.purchaseFieldMetadata.map((field) => field.Name);
    for (const key in mapping) {
      if (!dropfieldNames.includes(key))
        continue;
      const field = mapping[key];
      const dragField = tab === "Sale" ? saleDragFields.find((f) => f.id === (field == null ? void 0 : field.Source)) : purchaseDragFields.find((f) => f.id === (field == null ? void 0 : field.Source));
      const fallBackField = ((_a2 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a2.length) > 0 ? tab === "Sale" ? saleDragFields.find((f) => {
        var _a3;
        return f.id === ((_a3 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a3.at(0));
      }) : purchaseDragFields.find((f) => {
        var _a3;
        return f.id === ((_a3 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a3.at(0));
      }) : void 0;
      newPairs.set(key, { dragField, fallBackField });
    }
    return newPairs;
  };
  const mapAndSetDragFields = (newSelectedTab) => {
    var _a2, _b2, _c2, _d2, _e, _f;
    const fields = newSelectedTab === "Sale" ? (_c2 = (_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.TemplateFileReference) == null ? void 0 : _b2.SeparateTransactionData) == null ? void 0 : _c2.SaleFields : (_f = (_e = (_d2 = props.generationConfiguration) == null ? void 0 : _d2.TemplateFileReference) == null ? void 0 : _e.SeparateTransactionData) == null ? void 0 : _f.PurchaseFields;
    if (!fields) {
      if (newSelectedTab === "Sale") {
        setSaleDragFields([]);
      } else {
        setPurchaseDragFields([]);
      }
      return;
    }
    const dragFields = fields.map((field) => ({
      id: field.Index,
      label: field.Name,
      info: field.Name,
      FieldType: "String",
      type: "TemplateDataSource"
    }));
    if (newSelectedTab === "Sale") {
      setSaleDragFields(dragFields);
    } else {
      setPurchaseDragFields(dragFields);
    }
  };
  const changeTabAfterMappingSave = () => {
    window.scrollTo(0, 0);
    setIsValidated(false);
    if (selectedTab === "Sale") {
      setSalePairs(currentPairs);
      setCurrentPairs(purchasePairs);
      setSelectedTab("Purchase");
      return;
    }
    setPurchasePairs(currentPairs);
    setCurrentPairs(salePairs);
    setSelectedTab("Sale");
  };
  const onTabSelect = (e) => {
    if (selectedTab === e.detail.selectedTab)
      return;
    setIsValidated(false);
    if (e.detail.selectedTab === "Sale") {
      setPurchasePairs(currentPairs);
      setCurrentPairs(salePairs);
    } else {
      setSalePairs(currentPairs);
      setCurrentPairs(purchasePairs);
    }
    setSelectedTab(e.detail.selectedTab);
  };
  const hasEmptyRequiredFields = () => !isEveryRequiredDropFieldSet(currentPairs) || selectedTab === "Sale" && !isEveryRequiredDropFieldSet(purchasePairs) || selectedTab === "Purchase" && !isEveryRequiredDropFieldSet(salePairs);
  const handleNoCustomSourceMapping = () => {
    selectedTab === "Sale" ? setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0]))) : setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
    setSalePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
    setPurchasePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
  };
  const handleOnlyPurchaseMapping = () => {
    var _a2, _b2;
    const purchaseMappings = getMappedPairs((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.PurchaseMapping, "Purchase");
    if (selectedTab === "Sale") {
      setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
      setSalePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
      setPurchasePairs(purchaseMappings);
    } else {
      setCurrentPairs(purchaseMappings);
      setSalePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
      setPurchasePairs(purchaseMappings);
    }
  };
  const handleOnlySaleMapping = () => {
    var _a2, _b2;
    const saleMappings = getMappedPairs((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.SaleMapping, "Sale");
    if (selectedTab === "Sale") {
      setCurrentPairs(saleMappings);
      setSalePairs(saleMappings);
      setPurchasePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
    } else {
      setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
      setPurchasePairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.purchaseFieldMetadata.map((field) => [field.Name, void 0])));
      setSalePairs(saleMappings);
    }
  };
  const handleBothSaleAndPurchaseMapping = () => {
    var _a2, _b2, _c2, _d2;
    const saleMappings = getMappedPairs((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.SaleMapping, "Sale");
    const purchaseMappings = getMappedPairs((_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.CustomSourceConfiguration) == null ? void 0 : _d2.PurchaseMapping, "Purchase");
    if (selectedTab === "Sale") {
      setCurrentPairs(saleMappings);
      setSalePairs(saleMappings);
      setPurchasePairs(purchaseMappings);
    } else {
      setCurrentPairs(purchaseMappings);
      setSalePairs(saleMappings);
      setPurchasePairs(purchaseMappings);
    }
  };
  const isBothMappingUnchanged = () => !isSaleMappingChangedAfterSave && !isPurchaseMappingChangedAfterSave;
  const isSaleAndPurchaseMappingSaved = () => {
    var _a2, _b2, _c2, _d2;
    return ((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.SaleMapping) && ((_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.CustomSourceConfiguration) == null ? void 0 : _d2.PurchaseMapping);
  };
  const isOnlySaleMappingSaved = () => {
    var _a2, _b2, _c2, _d2;
    return ((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.SaleMapping) && !((_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.CustomSourceConfiguration) == null ? void 0 : _d2.PurchaseMapping);
  };
  const isOnlyPurchaseMappingSaved = () => {
    var _a2, _b2, _c2, _d2;
    return !((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.SaleMapping) && ((_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.CustomSourceConfiguration) == null ? void 0 : _d2.PurchaseMapping);
  };
  const [selectedTab, setSelectedTab] = useQueryState(TAB_QUERY_PREFIX, "Sale");
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [fieldMetadata, setFieldMetadata] = useState(void 0);
  const [saleDragFields, setSaleDragFields] = useState([]);
  const [purchaseDragFields, setPurchaseDragFields] = useState([]);
  const [isValidated, setIsValidated] = useState(false);
  const [currentPairs, setCurrentPairs] = useState(/* @__PURE__ */ new Map());
  const [salePairs, setSalePairs] = useState(/* @__PURE__ */ new Map());
  const [purchasePairs, setPurchasePairs] = useState(/* @__PURE__ */ new Map());
  const [isSaleMappingChangedAfterSave, setIsSaleMappingChangedAfterSave] = useState(false);
  const [isPurchaseMappingChangedAfterSave, setIsPurchaseMappingChangedAfterSave] = useState(false);
  const sharedCustomDataTemplate = useSharedCustomDataTemplate();
  useEffect(() => {
    selectedTab === "Sale" ? mapAndSetDragFields("Sale") : mapAndSetDragFields("Purchase");
    if (uploadedFile) {
      props.getAndSetGenerationConfiguration();
    }
  }, [uploadedFile]);
  useEffect(() => __async(this, null, function* () {
    var _a2, _b2, _c2, _d2;
    if (props.currentStep === 1 && ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately) && ((_c2 = (_b2 = props.generationConfiguration) == null ? void 0 : _b2.TemplateFileReference) == null ? void 0 : _c2.SeparateTransactionData)) {
      mapAndSetDragFields("Sale");
      mapAndSetDragFields("Purchase");
      setIsValidated(false);
    }
    if (props.currentStep === 1 && ((_d2 = props.generationConfiguration) == null ? void 0 : _d2.SalePurchaseSeparately)) {
      const saleFieldMetadata = yield props.getMetaDataConfiguration(props.loader, "Sale");
      const purchaseFieldMetadata = yield props.getMetaDataConfiguration(props.loader, "Purchase");
      setFieldMetadata({ saleFieldMetadata, purchaseFieldMetadata });
    }
  }), [(_a = props.generationConfiguration) == null ? void 0 : _a.SalePurchaseSeparately, (_c = (_b = props.generationConfiguration) == null ? void 0 : _b.TemplateFileReference) == null ? void 0 : _c.SeparateTransactionData, props.currentStep]);
  useEffect(() => {
    var _a2, _b2;
    if (props.currentStep === 1 && ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      if (!((_b2 = props.generationConfiguration) == null ? void 0 : _b2.CustomSourceConfiguration) && fieldMetadata) {
        handleNoCustomSourceMapping();
      }
      if (isBothMappingUnchanged() && fieldMetadata) {
        if (isOnlyPurchaseMappingSaved()) {
          handleOnlyPurchaseMapping();
        }
        if (isOnlySaleMappingSaved()) {
          handleOnlySaleMapping();
        }
        if (isSaleAndPurchaseMappingSaved()) {
          handleBothSaleAndPurchaseMapping();
        }
      }
    }
  }, [saleDragFields, purchaseDragFields, fieldMetadata]);
  useEffect(() => {
    var _a2;
    if (props.currentStep === 1 && !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      setCurrentPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.saleFieldMetadata.map((field) => [field.Name, void 0])));
    }
  }, [(_d = props.generationConfiguration) == null ? void 0 : _d.SalePurchaseSeparately]);
  useEffect(() => {
    if (props.currentStep !== 1) {
      setSalePairs(/* @__PURE__ */ new Map());
      setPurchasePairs(/* @__PURE__ */ new Map());
      setCurrentPairs(/* @__PURE__ */ new Map());
      setUploadedFile(void 0);
      setPurchaseDragFields([]);
      setSaleDragFields([]);
      setIsSaleMappingChangedAfterSave(false);
      setIsPurchaseMappingChangedAfterSave(false);
    }
  }, [props.currentStep]);
  const tabsTemplate = () => html`
		<dc-tabs
			.customClass=${"lg:text-xl"}
			.data=${[
    {
      name: TAB_NAMES.Sale,
      label: i18next.t("Sale"),
      template: html` <div class="mt-6">${dataMappingTemplate()}</div> `
    },
    {
      name: TAB_NAMES.Purchase,
      label: i18next.t("Purchase"),
      template: html` <div class="mt-6">${dataMappingTemplate()}</div> `
    }
  ]}
			.selectedTab=${selectedTab}
			@tabSelected=${(e) => onTabSelect(e)}
		></dc-tabs>
	`;
  const fallBackFieldTemplate = (dropFieldName) => {
    var _a2;
    return ((_a2 = currentPairs == null ? void 0 : currentPairs.get(dropFieldName)) == null ? void 0 : _a2.fallBackField) ? fallBackPairTemplate(dropFieldName) : emptyFallBackFieldTemplate(dropFieldName);
  };
  const emptyFallBackFieldTemplate = (dropFieldName) => html`
		<dc-dropfield
			.class=${"w-36 px-4 py-2 text-center justify-center border-2 rounded-xl border-solid px-4 py-2 hover:border-dashed hover:border-gray-500 bg-white"}
			.emptyText=${`+ ${i18next.t("Add Fallback")}`}
			.allowedDragTypes=${["TemplateDataSource"]}
			.onDrop=${(e) => {
    var _a2, _b2, _c2;
    const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
    if (draggedItem.id === ((_b2 = (_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField) == null ? void 0 : _b2.id)) {
      notifyWarning(i18next.t("You cannot set the same field as fallback."));
      return;
    }
    const newPairs = new Map(currentPairs);
    newPairs.set(dropFieldName, { dragField: (_c2 = currentPairs.get(dropFieldName)) == null ? void 0 : _c2.dragField, fallBackField: draggedItem });
    setCurrentPairs(newPairs);
    selectedTab === "Sale" ? setIsSaleMappingChangedAfterSave(true) : setIsPurchaseMappingChangedAfterSave(true);
  }}
		></dc-dropfield>
	`;
  const fallBackPairTemplate = (dropFieldName) => html`
		<div class="w-36 flex gap-4 items-center">
			<div
				class="flex justify-between gap-4 items-center w-36 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
				title=${currentPairs.get(dropFieldName).fallBackField.label}
			>
				<div class="truncate">${currentPairs.get(dropFieldName).fallBackField.label}</div>
				<span
					class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
					@click=${() => {
    var _a2;
    const newPairs = new Map(currentPairs);
    newPairs.set(dropFieldName, { dragField: (_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField, fallBackField: void 0 });
    setCurrentPairs(newPairs);
    selectedTab === "Sale" ? setIsSaleMappingChangedAfterSave(true) : setIsPurchaseMappingChangedAfterSave(true);
  }}
				>
					${closeIcon("fill-black w-3 h-3")}
				</span>
			</div>
		</div>
	`;
  const dropfieldTemplate = (dropFieldName, type) => {
    var _a2, _b2, _c2;
    return html`
		<div class=${classNames("flex items-center border shadow-lg rounded-xl px-6 py-4 gap-4")}>
			<div class="flex flex-col gap-1 basis-2/5"><span class="font-semibold">${TAX_DESK_DATA_LABELS[dropFieldName]}${isFieldRequired(dropFieldName) ? "*" : ""}</span><span>${type}</span></div>
			${((_a2 = currentPairs.get(dropFieldName)) == null ? void 0 : _a2.dragField) ? html`
						<div class="basis-3/5 flex gap-4 items-center">
							<div
								class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
								title=${currentPairs.get(dropFieldName).dragField.label}
							>
								<div class="truncate">${currentPairs.get(dropFieldName).dragField.label}</div>
								<span
									class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
									@click=${() => {
      const newPairs = new Map(currentPairs);
      newPairs.set(dropFieldName, void 0);
      setCurrentPairs(newPairs);
      selectedTab === "Sale" ? setIsSaleMappingChangedAfterSave(true) : setIsPurchaseMappingChangedAfterSave(true);
    }}
								>
									${closeIcon("fill-black w-3 h-3")}
								</span>
							</div>
							${fallBackFieldTemplate(dropFieldName)}
						</div>
				  ` : html`
						<div class="flex justify-end basis-3/5 gap-4 items-center">
							<dc-dropfield
								.class=${classNames("px-4 w-60 py-2 text-center justify-center border-2 rounded-xl border-solid hover:border-dashed", {
      "border-red-500 bg-red-100": isValidated && !((_b2 = currentPairs.get(dropFieldName)) == null ? void 0 : _b2.dragField) && isFieldRequired(dropFieldName),
      "hover:border-gray-500 bg-gray-200": !isValidated || ((_c2 = currentPairs.get(dropFieldName)) == null ? void 0 : _c2.dragField) || !isFieldRequired(dropFieldName)
    })}
								.emptyText=${i18next.t("Drop data mapping here")}
								.allowedDragTypes=${["TemplateDataSource"]}
								.onDrop=${(e) => {
      var _a3;
      const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
      const newPairs = new Map(currentPairs);
      newPairs.set(dropFieldName, { dragField: draggedItem, fallBackField: (_a3 = currentPairs.get(dropFieldName)) == null ? void 0 : _a3.fallBackField });
      setCurrentPairs(newPairs);
      selectedTab === "Sale" ? setIsSaleMappingChangedAfterSave(true) : setIsPurchaseMappingChangedAfterSave(true);
    }}
							></dc-dropfield>
						</div>
				  `}
		</div>
	`;
  };
  const uploadTransactionDataButtonTemplate = () => {
    var _a2;
    return html`
		<dc-fileupload2
			class="self-start w-fit"
			.label=${i18next.t("Upload Sample Data File")}
			.withLoader=${isFileUploading}
			.loaderPosition=${"right"}
			.showFileLink=${false}
			.accept=${FormatTypeMapping.get((_a2 = props.generationConfiguration) == null ? void 0 : _a2.AvailableTemplates[0])}
			@upload=${(e) => __async(this, null, function* () {
      return handleUploadTransactionDataButtonClick(e);
    })}
		></dc-fileupload2>
	`;
  };
  const taxDeskDataTemplate = () => html`
		<div class="flex flex-col gap-2 w-full">
			<div class="text-xl font-bold">${i18next.t("To be mapped fields")}</div>
			<div>${i18next.t("Fields with * are mandatory to map, the rest of the fields are optional.")}</div>
			<div class="flex flex-col gap-4 w-full">
				${Array.from(currentPairs.keys()).map(
    (dropFieldName) => {
      var _a2, _b2;
      return dropfieldTemplate(
        dropFieldName,
        selectedTab === "Sale" ? (_a2 = fieldMetadata.saleFieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _a2.Type : (_b2 = fieldMetadata.purchaseFieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _b2.Type
      );
    }
  )}
			</div>
		</div>
	`;
  const uploadedDataTemplate = () => {
    const currentDragFields = selectedTab === "Sale" ? saleDragFields : purchaseDragFields;
    return (currentDragFields == null ? void 0 : currentDragFields.length) > 0 ? html`
					<div class="sticky top-20 flex flex-col gap-4 max-h-[600px] w-[340px] shadow-xl border rounded-l-xl px-8 py-6 overflow-y-auto font-semibold">
						${currentDragFields.map((field) => sharedCustomDataTemplate.draggableItemTemplate(field.label, field.info, field.id))}
					</div>
			  ` : "";
  };
  const uploadSectionTemplate = () => {
    var _a2, _b2, _c2, _d2, _e, _f, _g, _h, _i;
    const file = selectedTab === "Sale" ? { Name: (_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.TemplateFileReference) == null ? void 0 : _b2.SaleFileName, Reference: (_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.TemplateFileReference) == null ? void 0 : _d2.SaleReference } : { Name: (_f = (_e = props.generationConfiguration) == null ? void 0 : _e.TemplateFileReference) == null ? void 0 : _f.PurchaseFileName, Reference: (_h = (_g = props.generationConfiguration) == null ? void 0 : _g.TemplateFileReference) == null ? void 0 : _h.PurchaseReference };
    return html`
			<div class="flex flex-col gap-2">
				<div class="text-xl font-bold">${i18next.t("Upload Sample Transaction Data File")}</div>
				<div class="flex flex-wrap gap-2 items-center">
					${uploadTransactionDataButtonTemplate()}
					<div
						class=${classNames("flex gap-2 items-center", {
      hidden: !(file == null ? void 0 : file.Name)
    })}
					>
						${fileIcon(file == null ? void 0 : file.Name)}

						<div
							class="hover:underline cursor-pointer truncate"
							title=${(_i = file == null ? void 0 : file.Name) != null ? _i : ""}
							@click=${() => __async(this, null, function* () {
      const downloadFile = yield props.getTemplateFile(selectedTab, props.loader);
      simulateDownload(downloadFile);
    })}
						>
							${file == null ? void 0 : file.Name}
						</div>
					</div>
				</div>
			</div>
		`;
  };
  const dataMappingTemplate = () => html`
		<div class="flex gap-8 w-full">
			<div class="flex flex-col w-full gap-4 max-w-[750px]">
				<div>${i18next.t("Please make sure that you save a mapping to all sides. Use the Save button at the bottom of the page to save current mapping for this side.")}</div>

				${taxDeskDataTemplate()}
			</div>
			<div class="flex flex-col gap-2 w-full">${uploadSectionTemplate()} ${uploadedDataTemplate()}</div>
		</div>
	`;
  const mapAllRequiredFieldsErrorTemplate = () => !isEveryRequiredDropFieldSet(currentPairs) && isValidated ? html`<div class="text-red-500 font-semibold">
					${selectedTab === "Sale" ? i18next.t("Please map all required fields at the Tax Desk sale data list.") : i18next.t("Please map all required fields at the Tax Desk purchase data list.")}
			  </div> ` : "";
  const template = () => {
    var _a2, _b2, _c2, _d2;
    return html`
		<div class="m-8 flex flex-col gap-4 w-full">
			<div class="flex flex-col gap-4 w-full">
				${tabsTemplate()}
				<div>${i18next.t("After you saved a mapping for both sides, please use the next button to continue to the tax code setup.")}</div>
				<div class="flex gap-4 items-center">${PrimaryButton({ class: "max-w-fit", text: i18next.t("Save mapping"), onClick: saveCurrentMapping })} ${mapAllRequiredFieldsErrorTemplate()}</div>
			</div>
			<div class="flex items-center flex-wrap gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(0) })}
				${PrimaryButton({
      class: "w-32 my-6",
      text: i18next.t("Next"),
      disabled: hasEmptyRequiredFields() || isSaleMappingChangedAfterSave || isPurchaseMappingChangedAfterSave || !((_b2 = (_a2 = props.generationConfiguration) == null ? void 0 : _a2.CustomSourceConfiguration) == null ? void 0 : _b2.PurchaseMapping) || !((_d2 = (_c2 = props.generationConfiguration) == null ? void 0 : _c2.CustomSourceConfiguration) == null ? void 0 : _d2.SaleMapping),
      onClick: () => props.setStep(2)
    })}
			</div>
		</div>
	`;
  };
  return { template, uploadedFile, selectedTab };
}
