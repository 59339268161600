import { AddSku } from "./AddSku";
import { AddAmazonSku } from "./AddAmazonSku";
import { AddCustomSku } from "./AddCustomSku";
export class AddSkuFactory {
  static create($type) {
    if ($type === "V1.ComplianceMasterData.AddSku") {
      const result = new AddSku();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.AddAmazonSku") {
      const result = new AddAmazonSku();
      return result;
    }
    if ($type === "V1.ComplianceMasterData.AddCustomSku") {
      const result = new AddCustomSku();
      return result;
    }
    throw new Error($type + " not found");
  }
}
