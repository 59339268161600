var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useAmazonSkus } from "GlobalShared/components/sku/amazon-skus";
import {
  mapGtuCodes,
  mapSearchResult,
  mapSkuValidationMessage,
  SkuFieldNames
} from "GlobalShared/components/sku/AmazonSkuModels";
import { useClient, useState } from "GlobalShared/haunted/CustomHooks";
import { notifyErrorWithWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
import { DUMMY_PERMISSION_MANAGER } from "GlobalShared/models/common/PermissionModels";
import { component, useEffect } from "haunted";
import { ComplianceMasterData as ComplianceMasterDataClient, Company as CompanyClient, GenerationConfiguration as GenerationConfigurationClient } from "WebServices/APIs/self/clients";
import { AmountRange } from "WebServices/APIs/self/models/V1/AmountRange";
import { Money } from "WebServices/APIs/self/models/V1/Common";
import { AddAmazonSku, AddCustomSku, SkuCommodityCode, UpdateAmazonSku, UpdateCustomSku } from "WebServices/APIs/self/models/V1/ComplianceMasterData";
import { CommodityCodeFilter, ExportRequest, Field, Filter, Pager, SearchRequest, CommodityCodeField } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchSku";
import { SearchRequest as WebCommoditySearchRequest } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchCommodityCode";
import { SkuFieldBaseOrder } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchSku/SkuFieldBase";
import { currentLocale } from "../../globals";
import { mapCompanyDetailToCompanyServicesModel } from "GlobalShared/mappers/ServicesMapper";
import { useWorldCountries } from "WebComponents/hooks/WebCustomHooks";
import { FilterType } from "WebServices/APIs/self/models/V1/ComplianceMasterData/SearchSku/Filter";
export function AdminAmazonSkus() {
  const getFilter = (gridState, showOnlyMissing, commodityCodeFields) => {
    const clientFilter = new Filter({
      CommodityCodes: []
    });
    clientFilter.ShowOnlyMissing = showOnlyMissing;
    if (gridState.appliedFilters.length > 0) {
      gridState.appliedFilters.forEach((filter) => {
        if (filter.filterProps.selectedValues) {
          if (filter.field === SkuFieldNames.SkuId) {
            clientFilter.Sku = filter.filterProps.selectedValues;
          }
          if (filter.field === SkuFieldNames.SellerEntityId) {
            clientFilter.SellerEntityId = filter.filterProps.selectedValues;
          }
          if (filter.field === SkuFieldNames.CommodityCode0) {
            clientFilter.CommodityCodes.push(
              new CommodityCodeFilter({
                Code: filter.filterProps.selectedValues,
                Year: commodityCodeFields[0].year
              })
            );
          }
          if (filter.field === SkuFieldNames.CommodityCode1) {
            clientFilter.CommodityCodes.push(
              new CommodityCodeFilter({
                Code: filter.filterProps.selectedValues,
                Year: commodityCodeFields[1].year
              })
            );
          }
          if (filter.field === SkuFieldNames.CommodityCode2) {
            clientFilter.CommodityCodes.push(
              new CommodityCodeFilter({
                Code: filter.filterProps.selectedValues,
                Year: commodityCodeFields[2].year
              })
            );
          }
          if (filter.field === SkuFieldNames.CommodityCode3) {
            clientFilter.CommodityCodes.push(
              new CommodityCodeFilter({
                Code: filter.filterProps.selectedValues,
                Year: commodityCodeFields[3].year
              })
            );
          }
          if (filter.field === SkuFieldNames.CommodityCode4) {
            clientFilter.CommodityCodes.push(
              new CommodityCodeFilter({
                Code: filter.filterProps.selectedValues,
                Year: commodityCodeFields[4].year
              })
            );
          }
          if (filter.field === SkuFieldNames.ItemIdentifier) {
            clientFilter.ItemIdentifiers = filter.filterProps.selectedValues;
          }
        }
        if (filter.field === SkuFieldNames.Price) {
          if (filter.filterProps.min || filter.filterProps.max) {
            clientFilter.Price = new AmountRange({
              From: filter.filterProps.min !== void 0 ? filter.filterProps.min : 0,
              To: filter.filterProps.max !== void 0 ? filter.filterProps.max : 1e6
            });
          }
        }
      });
    }
    clientFilter.Type = isCustomSource ? FilterType.Custom : FilterType.Amazon;
    return clientFilter;
  };
  const isCommodityCodeSortChanged = (orderBy) => {
    return orderBy === "CommodityCode0" || orderBy === "CommodityCode1" || orderBy === "CommodityCode2" || orderBy === "CommodityCode3" || orderBy === "CommodityCode4";
  };
  const loadSkus = (gridState, showOnlyMissing) => __async(this, null, function* () {
    let orderBy;
    if (isCommodityCodeSortChanged(gridState.orderBy)) {
      orderBy = new CommodityCodeField({
        Order: gridState.orderDir === "asc" ? SkuFieldBaseOrder.Ascending : SkuFieldBaseOrder.Descending,
        OrderByField: gridState.orderBy,
        Year: amazonSkusHook.commodityCodeFields[parseInt(gridState.orderBy.charAt(gridState.orderBy.length - 1))].year
      });
    } else {
      orderBy = new Field({
        Order: gridState.orderDir === "asc" ? SkuFieldBaseOrder.Ascending : SkuFieldBaseOrder.Descending,
        OrderByField: gridState.orderBy
      });
    }
    const response = yield complianceMasterDataClient.searchSKUs({
      body: new SearchRequest({
        Filter: getFilter(gridState, showOnlyMissing, amazonSkusHook.commodityCodeFields),
        Pager: new Pager({
          Skip: gridState.pageIndex * gridState.pageSize,
          Top: gridState.pageSize,
          OrderBy: [orderBy]
        })
      })
    });
    return mapSearchResult(response.data);
  });
  const loadGtuCodes = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceMasterDataClient.getGTUCodes();
        return mapGtuCodes(response.data);
      }),
      (error) => {
        throw error;
      }
    );
  });
  const downloadSkus = (exportPageSize, gridState, showOnlyMissing) => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.exportSkus({
      body: new ExportRequest({
        Filter: getFilter(gridState, showOnlyMissing, amazonSkusHook.commodityCodeFields),
        PageSize: exportPageSize
      })
    });
    simulateDownload(response.data);
  });
  const downloadCurrencies = () => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.exportCurrencies();
    simulateDownload(response.data);
  });
  const downloadCommodityCodes = () => __async(this, null, function* () {
    const response = yield complianceMasterDataClient.exportCommodityCodes();
    simulateDownload(response.data);
  });
  const uploadSkus = (file) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const uploadResult = yield complianceMasterDataClient.uploadSkus({
          file
        });
        return {
          isSuccess: uploadResult.data.IsSuccess,
          validationMessages: mapSkuValidationMessage(uploadResult.data.ValidationMessages ? uploadResult.data.ValidationMessages : [])
        };
      }),
      (error) => {
        throw error;
      }
    );
  });
  const updateAmazonSku = (sku) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateSku({
          id: sku.Id,
          body: new UpdateAmazonSku({
            CommodityCodes: sku.SelectedCommodities.map(
              (c) => new SkuCommodityCode({
                Code: c.Code,
                ValidFromYear: c.ValidFromYear
              })
            ),
            ItemIdentifier: sku.ItemIdentifier,
            Price: new Money(__spreadValues({}, sku.Price))
          })
        });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const addAmazonSku = (sku) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.addSku({
          body: new AddAmazonSku({
            SkuId: sku.SkuId,
            SellerEntityId: sku.SellerEntityId,
            CommodityCodes: sku.SelectedCommodities.map(
              (c) => new SkuCommodityCode({
                Code: c.Code,
                ValidFromYear: c.ValidFromYear
              })
            ),
            ItemIdentifier: sku.ItemIdentifier,
            Price: new Money(__spreadValues({}, sku.Price))
          })
        });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const addCustomSku = (sku) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.addSku({
          body: new AddCustomSku({
            SkuId: sku.SkuId,
            CommodityCodes: sku.SelectedCommodities.map(
              (c) => new SkuCommodityCode({
                Code: c.Code,
                ValidFromYear: c.ValidFromYear
              })
            ),
            ItemIdentifier: sku.ItemIdentifier
          })
        });
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const updateCustomSku = (sku) => __async(this, null, function* () {
    yield withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.updateSku({
          id: sku.Id,
          body: new UpdateCustomSku({
            CommodityCodes: sku.SelectedCommodities.map(
              (c) => new SkuCommodityCode({
                Code: c.Code,
                ValidFromYear: c.ValidFromYear
              })
            ),
            ItemIdentifier: sku.ItemIdentifier
          })
        });
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  });
  const removeSku = (skuId) => __async(this, null, function* () {
    withErrorHandling(
      () => __async(this, null, function* () {
        yield complianceMasterDataClient.removeSku({ id: skuId });
      }),
      (error) => {
        throw error;
      }
    );
  });
  const getCommodityCodes = (year) => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield complianceMasterDataClient.getCommodityCodes({
          body: new WebCommoditySearchRequest({
            Year: year
          })
        });
        return response;
      }),
      (error) => {
        throw error;
      }
    );
  });
  const getCompanyDetails = () => __async(this, null, function* () {
    return withErrorHandling(
      () => __async(this, null, function* () {
        const response = yield companyClient.getCompanyDetail({ locale: currentLocale });
        return mapCompanyDetailToCompanyServicesModel(worldCountries, response.data);
      }),
      (error) => {
        throw error;
      }
    );
  });
  const getIsCustomSource = () => __async(this, null, function* () {
    const isCustomSourceClient = (yield generationConfigurationClient.get()).data.IsCustomSource;
    setIsCustomSource(isCustomSourceClient);
  });
  const complianceMasterDataClient = useClient(ComplianceMasterDataClient);
  const companyClient = useClient(CompanyClient);
  const worldCountries = useWorldCountries(currentLocale);
  const generationConfigurationClient = useClient(GenerationConfigurationClient);
  const [isCustomSource, setIsCustomSource] = useState(false);
  useEffect(() => __async(this, null, function* () {
    yield getIsCustomSource();
  }), []);
  const amazonSkusHook = useAmazonSkus({
    isAdmin: false,
    permissionManager: DUMMY_PERMISSION_MANAGER,
    isCustomSource,
    loadSkus,
    loadGtuCodes,
    downloadSkus,
    uploadSkus,
    updateSku: isCustomSource ? updateCustomSku : updateAmazonSku,
    addSku: isCustomSource ? addCustomSku : addAmazonSku,
    removeSku,
    downloadCurrencies,
    downloadCommodityCodes,
    getCommodityCodes,
    getCompanyDetails
  });
  return amazonSkusHook.mainTemplate;
}
customElements.define("ww-admin-amazon-skus", component(AdminAmazonSkus, { useShadowDOM: false }));
