import { IndividualDetail as AdminIndividualDetail, CompanyDetail as AdminCompanyInfoDetail } from "AdminServices/APIs/self/models/V1/CompanyInfo";
import { IndividualDetail as WebIndividualDetail, CompanyDetail as WebCompanyInfoDetail } from "WebServices/APIs/self/models/V1/CompanyInfo";
import { BankAccount as AdminBankAccount } from "AdminServices/APIs/self/models/V1/CompanyInfo/Detail";
import { VatRegistration as WebVatRegistration } from "WebServices/APIs/self/models/V1/Companies/CompanyInfo";
import { getFullName, resolveCountryName } from "GlobalShared/helpers/questionnaireHelper";
import { EoriRegistrationRegistrationType as AdminEoriRegistrationRegistrationType } from "AdminServices/APIs/self/models/V1/CompanyInfo/Detail/EoriRegistrations/EoriRegistration";
import {
  FilingAgentSubscription as WebFilingAgentSubscription,
  FiscalRepresentativeSubscription as WebFiscalRepresentativeSubscription,
  IntrastatInSubscription as WebIntrastatInSubscription,
  IntrastatOutSubscription as WebIntrastatOutSubscription,
  IossComplianceSubscription as WebIossComplianceSubscription,
  RetrospectiveReturnSubscription as WebRetrospectiveReturnSubscription,
  VatComplianceSubscription as WebVatComplianceSubscription,
  OssComplianceSubscription as WebOssComplianceSubscription
} from "WebServices/APIs/self/models/V1/CompanyInfo/Subscriptions";
import {
  FilingAgentSubscription as AdminFilingAgentSubscription,
  FiscalRepresentativeSubscription as AdminFiscalRepresentativeSubscription,
  IntrastatInSubscription as AdminIntrastatInSubscription,
  IntrastatOutSubscription as AdminIntrastatOutSubscription,
  IossComplianceSubscription as AdminIossComplianceSubscription,
  RetrospectiveReturnSubscription as AdminRetrospectiveReturnSubscription,
  VatComplianceSubscription as AdminVatComplianceSubscription,
  OssComplianceSubscription as AdminOssComplianceSubscription
} from "AdminServices/APIs/self/models/V1/CompanyInfo/Subscriptions";
const mapCompanyDetails = (companyDetail) => {
  var _a;
  const vm = {
    id: companyDetail.Id,
    legalStatus: getLegalStatus(companyDetail.BusinessDetail),
    phone: companyDetail.ContactPhone,
    companyName: void 0,
    contactTitle: void 0,
    contactFirstName: void 0,
    contactLastName: void 0,
    contactPosition: companyDetail.BusinessDetail.ContactPosition,
    addressCity: companyDetail.BusinessDetail.Address.City,
    addressZip: companyDetail.BusinessDetail.Address.Zip,
    addressStreet: companyDetail.BusinessDetail.Address.Street,
    addressStreetNo: companyDetail.BusinessDetail.Address.StreetNumber,
    addressProvince: companyDetail.BusinessDetail.Address.Province,
    preferredLanguage: companyDetail.ContactLanguage,
    countryOfEstablishment: companyDetail.BusinessDetail.EstablishmentCountryCode,
    vatNumber: (_a = companyDetail.BusinessDetail.VatNumber) == null ? void 0 : _a.substring(2),
    businessType: companyDetail.BusinessType,
    countryCodeOfProductOrigin: companyDetail.BusinessDetail.CountryCodeOfProductOrigin
  };
  if (companyDetail.BusinessDetail instanceof WebIndividualDetail || companyDetail.BusinessDetail instanceof AdminIndividualDetail) {
    vm.contactTitle = companyDetail.BusinessDetail.Name.Title;
    vm.contactFirstName = companyDetail.BusinessDetail.Name.FirstName;
    vm.contactLastName = companyDetail.BusinessDetail.Name.LastName;
  } else if (companyDetail.BusinessDetail instanceof AdminCompanyInfoDetail || companyDetail.BusinessDetail instanceof WebCompanyInfoDetail) {
    vm.companyName = companyDetail.BusinessDetail.CompanyName;
    vm.contactTitle = companyDetail.BusinessDetail.ContactName.Title;
    vm.contactFirstName = companyDetail.BusinessDetail.ContactName.FirstName;
    vm.contactLastName = companyDetail.BusinessDetail.ContactName.LastName;
  }
  return vm;
};
export function mapCompanyDetailToCompanyServicesModel(worldCountries, companyDetail) {
  var _a, _b;
  const adminCompanyDetail = companyDetail;
  return {
    Id: companyDetail.Id,
    CompanyDetails: mapCompanyDetails(companyDetail),
    EstablishmentCountryName: resolveCountryName(worldCountries, companyDetail.BusinessDetail.EstablishmentCountryCode),
    EstablishmentCountryCode: companyDetail.BusinessDetail.EstablishmentCountryCode,
    QuestionnaireStatus: companyDetail.QuestionnaireStatus,
    IsRejected: companyDetail.Status === "Rejected",
    IsInactive: companyDetail.Status === "Inactive",
    FullName: getFullName(companyDetail.BusinessDetail),
    GlobalServices: mapGlobalServices(companyDetail),
    CountryServices: mapCountryServices(companyDetail),
    VatRegistrations: mapVatRegistrations(companyDetail.VatRegistrations),
    IsInvoicingEnabled: adminCompanyDetail.IsInvoicingEnabled,
    IsVatNumberValid: adminCompanyDetail.IsVatNumberValid,
    ClientAcceptance: adminCompanyDetail.ClientAcceptance,
    ClientAcceptanceValidTo: adminCompanyDetail.ClientAcceptanceValidTo,
    ClientSource: companyDetail.ClientSource,
    AmlFileReference: (_a = adminCompanyDetail == null ? void 0 : adminCompanyDetail.AntiMoneyLaundering) == null ? void 0 : _a.DocumentReference,
    AmlReceivedAt: (_b = adminCompanyDetail == null ? void 0 : adminCompanyDetail.AntiMoneyLaundering) == null ? void 0 : _b.ReceivedAt
  };
}
export const mapDefaultCompanyConfiguration = (companyConfiguration) => {
  const vm = {
    useAmazonTemplate: companyConfiguration.UseAmazonTemplate,
    useMpTemplate: companyConfiguration.UseMpTemplate,
    amazonConfiguration: {
      sellerEntities: companyConfiguration.AmazonConfiguration.SellerEntities.map((se) => ({
        id: se.Id,
        name: se.Name,
        mwsAuthToken: se.MwsAuthToken,
        isEnabled: se.IsEnabled,
        isAutomated: se.IsAutomated
      })),
      useAmazonRates: companyConfiguration.AmazonConfiguration.UseAmazonRates
    }
  };
  return vm;
};
function getLegalStatus(companyDetail) {
  if (companyDetail instanceof AdminIndividualDetail || companyDetail instanceof WebIndividualDetail) {
    return "Individual";
  } else {
    if (companyDetail instanceof AdminCompanyInfoDetail || companyDetail instanceof WebCompanyInfoDetail) {
      return companyDetail.LegalStatus;
    }
  }
  return "";
}
function mapGlobalServices(companyDetail) {
  const ukEori = companyDetail.EoriRegistrations.find((e) => e.RegistrationType === AdminEoriRegistrationRegistrationType.UK);
  const euEori = companyDetail.EoriRegistrations.find((e) => e.RegistrationType === AdminEoriRegistrationRegistrationType.EU);
  const ukEoriValidFrom = ukEori == null ? void 0 : ukEori.StartDate;
  const euEoriValidFrom = euEori == null ? void 0 : euEori.StartDate;
  const iossCompliance = companyDetail.Subscriptions.find(
    (s) => s instanceof WebIossComplianceSubscription || s instanceof AdminIossComplianceSubscription
  );
  const iossRegistration = companyDetail.IossRegistrations ? companyDetail.IossRegistrations[0] : void 0;
  const adminIossRegistration = iossRegistration;
  const ossCompliance = companyDetail.Subscriptions.find(
    (s) => s instanceof WebOssComplianceSubscription || s instanceof AdminOssComplianceSubscription
  );
  const ossRegistration = companyDetail.OssRegistration ? companyDetail.OssRegistration : void 0;
  const adminOssRegistration = ossRegistration;
  const iossService = iossCompliance ? {
    ValidFrom: iossCompliance.StartDate,
    ComplianceValidTo: iossCompliance.EndDate,
    ComplianceSubscriptionStatus: iossCompliance.Status,
    ComplianceSubscriptionCancellationDate: iossCompliance.CancellationDate,
    ComplianceSubscriptionId: iossCompliance.Id,
    Tier: iossCompliance.Tier,
    PackageAmount: iossCompliance.PackageAmount,
    Id: iossRegistration == null ? void 0 : iossRegistration.Id,
    RegistrationStatus: iossRegistration == null ? void 0 : iossRegistration.Status,
    RegistrationType: iossRegistration == null ? void 0 : iossRegistration.RegistrationType,
    RegistrationStartDate: iossRegistration == null ? void 0 : iossRegistration.StartDate,
    MerchantId: adminIossRegistration == null ? void 0 : adminIossRegistration.MerchantId,
    ProductId: adminIossRegistration == null ? void 0 : adminIossRegistration.ProductId,
    CountryOfRegistration: (iossRegistration == null ? void 0 : iossRegistration.CountryOfRegistration) ? {
      Code: iossRegistration == null ? void 0 : iossRegistration.CountryOfRegistration.Code,
      Name: iossRegistration == null ? void 0 : iossRegistration.CountryOfRegistration.Name
    } : void 0,
    Errors: adminIossRegistration == null ? void 0 : adminIossRegistration.DesuclaErrors,
    IOSSCertificate: {
      CertificateReferenceId: iossRegistration == null ? void 0 : iossRegistration.CertificatesReferenceId,
      RegistrationNumber: iossRegistration == null ? void 0 : iossRegistration.RegistrationNumber,
      RegistrationDate: iossRegistration == null ? void 0 : iossRegistration.RegistrationDate
    },
    Documents: (iossRegistration == null ? void 0 : iossRegistration.Documents).map((doc) => ({
      Id: doc.Id,
      Name: doc.Name,
      DocumentReference: doc.DocumentReference,
      SignedDocumentReference: doc.SignedDocumentReference,
      Category: doc.Category
    })),
    DeregistrationInfo: adminIossRegistration.DateOfDeregistration ? {
      DeregisteredAt: adminIossRegistration.DateOfDeregistration,
      DocumentId: adminIossRegistration.DeregistrationConfirmationDocumentId
    } : void 0
  } : void 0;
  const ossService = ossCompliance ? {
    ValidFrom: ossCompliance.StartDate,
    ComplianceValidTo: ossCompliance.EndDate,
    ComplianceSubscriptionStatus: ossCompliance.Status,
    ComplianceSubscriptionCancellationDate: ossCompliance.CancellationDate,
    ComplianceSubscriptionId: ossCompliance.Id,
    Id: ossRegistration == null ? void 0 : ossRegistration.Id,
    RegistrationType: ossRegistration == null ? void 0 : ossRegistration.RegistrationType,
    RegistrationStartDate: ossRegistration == null ? void 0 : ossRegistration.StartDate,
    RegistrationStatus: ossRegistration == null ? void 0 : ossRegistration.Status,
    OSSCertificate: {
      CertificateReferenceId: ossRegistration == null ? void 0 : ossRegistration.CertificateReferenceId,
      RegistrationNumber: ossRegistration == null ? void 0 : ossRegistration.RegistrationNumber,
      RegistrationDate: ossRegistration == null ? void 0 : ossRegistration.RegistrationDate
    },
    Country: (ossRegistration == null ? void 0 : ossRegistration.Country) ? {
      Code: ossRegistration == null ? void 0 : ossRegistration.Country.Code,
      Name: ossRegistration == null ? void 0 : ossRegistration.Country.Name
    } : void 0,
    DeregistrationInfo: adminOssRegistration.DateOfDeregistration ? {
      DeregisteredAt: adminOssRegistration.DateOfDeregistration,
      DocumentId: adminOssRegistration.DeregistrationConfirmationDocumentId
    } : void 0,
    PaymentReference: ossRegistration.PaymentReference,
    IsSubmissionOnly: ossCompliance.IsSubmissionOnly
  } : void 0;
  return {
    UkEori: ukEoriValidFrom ? { ValidFrom: ukEoriValidFrom } : void 0,
    EuEori: euEoriValidFrom ? { ValidFrom: euEoriValidFrom } : void 0,
    IossService: iossService,
    OssService: ossService
  };
}
function mapCountryServices(companyDetail) {
  const result = [];
  companyDetail.VatRegistrations.forEach((vatRegistration) => {
    const filingAgentSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminFilingAgentSubscription || s instanceof WebFilingAgentSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const fiscalRepresentativeSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminFiscalRepresentativeSubscription || s instanceof WebFiscalRepresentativeSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const intrastatInSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminIntrastatInSubscription || s instanceof WebIntrastatInSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const intrastatOutSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminIntrastatOutSubscription || s instanceof WebIntrastatOutSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const retrospectiveReturnSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminRetrospectiveReturnSubscription || s instanceof WebRetrospectiveReturnSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const vatComplianceSubscription = companyDetail.Subscriptions.find(
      (s) => (s instanceof AdminVatComplianceSubscription || s instanceof WebVatComplianceSubscription) && s.CountryCode === vatRegistration.ServiceCountry.Code
    );
    const tempCountryService = {
      Country: vatRegistration.ServiceCountry,
      ContractFrom: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.StartDate,
      ContractTo: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.EndDate,
      DateOfRegistration: vatRegistration.StartDate,
      ComplianceSubscriptionStatus: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.Status,
      ComplianceSubscriptionCancellationDate: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.CancellationDate,
      ComplianceSubscriptionId: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.Id,
      VatNumber: vatRegistration.LocalVatNumber,
      FiscalRep: fiscalRepresentativeSubscription,
      IntrastatIn: intrastatInSubscription,
      IntrastatOut: intrastatOutSubscription,
      Retrospective: retrospectiveReturnSubscription,
      FilingAgent: filingAgentSubscription,
      IsSubmissionOnly: vatComplianceSubscription == null ? void 0 : vatComplianceSubscription.IsSubmissionOnly
    };
    result.push(tempCountryService);
  });
  return result;
}
function mapVatRegistrations(vatRegistrations) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
  const result = [];
  for (const vatRegistration of vatRegistrations) {
    const vatReg = {
      Id: vatRegistration.Id,
      ServiceCountry: { Code: vatRegistration.ServiceCountry.Code, Name: vatRegistration.ServiceCountry.Name },
      Status: vatRegistration.Status,
      RegistrationDocumentStatus: vatRegistration.RegistrationDocumentStatus,
      ServiceKind: vatRegistration.ServiceKind,
      StartDate: vatRegistration.StartDate,
      PaymentReference: vatRegistration.PaymentReference,
      Documents: vatRegistration.Documents ? vatRegistration.Documents.map((doc) => {
        const docVM = {
          Status: doc.Status,
          ErrorCode: doc.ErrorCode,
          TemplateId: doc.TemplateId,
          TemplateName: doc.TemplateName,
          DocumentReference: doc.DocumentReference,
          SignedDocumentReference: doc.SignedDocumentReference,
          Category: doc.Category
        };
        return docVM;
      }) : [],
      LocalVatCertificate: {
        LocalVatNumber: vatRegistration.LocalVatNumber,
        LocalVatRegistrationDate: vatRegistration.LocalVatRegistrationDate,
        LocalVATCertificateReferenceId: vatRegistration.LocalVATCertificatesReferenceId
      },
      EUVatCertificate: {
        EUVatNumber: {
          CountryCode: (_a = vatRegistration.EUVatNumber) == null ? void 0 : _a.CountryCode,
          VatNumber: (_b = vatRegistration.EUVatNumber) == null ? void 0 : _b.VatNumber
        },
        EUVatRegistrationDate: vatRegistration.EUVatRegistrationDate,
        EUVATCertificateReferenceId: vatRegistration.EUVATCertificatesReferenceId
      },
      TaxIdentifier: vatRegistration.TaxIdentifier,
      TaxOfficeId: vatRegistration.TaxOfficeId,
      BankAccountConfig: mapBankAccountConfig(vatRegistration.BankAccountConfig),
      PrimaryVATFrequencies: (_d = (_c = vatRegistration.PrimaryVatFrequencies) == null ? void 0 : _c.map(mapFrequencyConfig)) != null ? _d : [],
      VLSFrequencies: (_f = (_e = vatRegistration.VlsFrequencies) == null ? void 0 : _e.map(mapFrequencyConfig)) != null ? _f : [],
      ESLFrequencies: (_h = (_g = vatRegistration.EslFrequencies) == null ? void 0 : _g.map(mapFrequencyConfig)) != null ? _h : [],
      SecondaryVATFrequencies: (_j = (_i = vatRegistration.SecondaryVatFrequencies) == null ? void 0 : _i.map(mapFrequencyConfig)) != null ? _j : [],
      IntrastatFrequencies: (_l = (_k = vatRegistration.IntrastatFrequencies) == null ? void 0 : _k.map(mapFrequencyConfig)) != null ? _l : [],
      LocalListingFrequencies: (_n = (_m = vatRegistration.LocalListingFrequencies) == null ? void 0 : _m.map(mapFrequencyConfig)) != null ? _n : [],
      DeregistrationInfo: vatRegistration.DeregistrationDate ? {
        DeregisteredAt: vatRegistration.DeregistrationDate,
        DocumentId: vatRegistration.DeregistrationConfirmationDocumentId
      } : void 0,
      PoARevocationInfo: vatRegistration.PoaRevocationDate ? {
        PoARevokedAt: vatRegistration.PoaRevocationDate,
        DocumentId: vatRegistration.PoaRevocationConfirmationDocumentId
      } : void 0,
      RegNotificationSubscriptions: vatRegistration instanceof WebVatRegistration ? [
        { isSubscribed: vatRegistration.IsEslSubscribed, eventType: "ESL" },
        { isSubscribed: vatRegistration.IsVlsSubscribed, eventType: "VLS" }
      ] : []
    };
    result.push(vatReg);
  }
  return result;
}
function mapFrequencyConfig(freq) {
  const freqConfigVM = {
    Id: freq.Id,
    FrequencyType: freq.FrequencyType,
    EFiling: freq.EFiling,
    FilingExtension: freq.FilingExtension,
    ValidFromYear: freq.ValidFrom.getFullYear(),
    ValidFromMonth: freq.ValidFrom.getMonth() + 1
  };
  return freqConfigVM;
}
function mapBankAccountConfig(bac) {
  if (bac === void 0) {
    return void 0;
  }
  const bankAccountConfigVM = {
    BankAccount: bac.BankAccount instanceof AdminBankAccount ? mapAdminBankAccount(bac.BankAccount) : mapWebBankAccount(bac.BankAccount),
    BankAccountRef: bac.BankAccountRef,
    BankAccountUrl: bac.BankAccountUrl
  };
  return bankAccountConfigVM;
}
function mapAdminBankAccount(acc) {
  if (acc === void 0) {
    return void 0;
  }
  const bankAccountVM = {
    BankAccountId: acc.BankAccountId,
    BankName: acc.BankName,
    Iban: acc.Iban,
    Swift: acc.Swift,
    BankAddress: acc.BankAddress,
    BeneficiaryAddress: acc.BeneficiaryAddress,
    BeneficiaryName: acc.BeneficiaryName
  };
  return bankAccountVM;
}
function mapWebBankAccount(acc) {
  if (acc === void 0) {
    return void 0;
  }
  const bankAccountVM = {
    BankName: acc.BankName,
    Iban: acc.Iban,
    Swift: acc.Swift,
    BankAddress: acc.BankAddress,
    BeneficiaryAddress: acc.BeneficiaryAddress,
    BeneficiaryName: acc.BeneficiaryName
  };
  return bankAccountVM;
}
export const mapRegNotificationSubscriptions = (subscriptions) => {
  return subscriptions.map((sub) => {
    const vm = {
      isSubscribed: sub.IsSubscribed,
      email: sub.UserEmail,
      userId: sub.UserId
    };
    return vm;
  });
};
