var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { closeIcon, fileIcon, templateTitle } from "GlobalShared/templates/commons";
import i18next from "i18next";
import { html } from "lit-html";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { useEffect, useState } from "GlobalShared/haunted/CustomHooks";
import classNames from "classnames";
import {
  mapPairsToCustomSourceMapping
} from "./CustomDataTemplateModels";
import { notifyErrorWithWarning, notifyWarning } from "GlobalShared/helpers/errorHelper";
import { FormatTypeMapping, TAX_DESK_DATA_LABELS } from "./CustomDataTemplateHelper";
import { useSharedCustomDataTemplate } from "./useSharedCustomDataTemplates";
import { simulateDownload } from "GlobalShared/helpers/fileHelper";
export function useOneFileConfiguration(props) {
  var _a;
  const isFieldRequired = (fieldName) => {
    var _a2;
    return (_a2 = fieldMetadata.find((m) => m.Name === fieldName)) == null ? void 0 : _a2.IsRequired;
  };
  const isEveryDragFieldSet = () => (dragFields == null ? void 0 : dragFields.filter((field) => Array.from(pairs.values()).every((val) => {
    var _a2;
    return ((_a2 = val == null ? void 0 : val.dragField) == null ? void 0 : _a2.id) !== (field == null ? void 0 : field.id);
  })).length) === 0;
  const isEveryRequiredDropFieldSet = () => !Array.from(pairs.keys()).some((key) => {
    var _a2;
    return isFieldRequired(key) && !((_a2 = pairs.get(key)) == null ? void 0 : _a2.dragField);
  });
  const handleNextButtonClick = () => __async(this, null, function* () {
    if (isEveryRequiredDropFieldSet()) {
      const mapping = mapPairsToCustomSourceMapping(pairs);
      try {
        props.loader.start();
        yield props.updateCustomSourceAllInOneMapping(mapping);
        props.setStep(2);
      } catch (error) {
        notifyErrorWithWarning(error);
      } finally {
        props.loader.stop();
      }
    }
    setIsValidated(true);
  });
  const handleUploadTransactionDataButtonClick = (e) => __async(this, null, function* () {
    setIsFileUploading(true);
    yield props.updateTemplateReference("AllInOne", e.detail.files[0]);
    setUploadedFile(e.detail.files[0]);
    setIsFileUploading(false);
  });
  const getMappedPairs = (mapping) => {
    var _a2;
    if (!mapping)
      return new Map(fieldMetadata.map((field) => [field.Name, void 0]));
    const newPairs = /* @__PURE__ */ new Map();
    const dropfieldNames = fieldMetadata.map((field) => field.Name);
    for (const key in mapping) {
      if (!dropfieldNames.includes(key))
        continue;
      const field = mapping[key];
      const dragField = dragFields == null ? void 0 : dragFields.find((f) => f.id === (field == null ? void 0 : field.Source));
      const fallBackField = ((_a2 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a2.length) > 0 ? dragFields == null ? void 0 : dragFields.find((f) => {
        var _a3;
        return f.id === ((_a3 = field == null ? void 0 : field.FallbackSources) == null ? void 0 : _a3.at(0));
      }) : void 0;
      newPairs.set(key, { dragField, fallBackField });
    }
    return newPairs;
  };
  const setPairsToDefault = () => setPairs(new Map(fieldMetadata == null ? void 0 : fieldMetadata.map((field) => [field.Name, void 0])));
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [fieldMetadata, setFieldMetadata] = useState(void 0);
  const [dragFields, setDragFields] = useState(void 0);
  const [isValidated, setIsValidated] = useState(false);
  const [pairs, setPairs] = useState(/* @__PURE__ */ new Map());
  const sharedCustomDataTemplate = useSharedCustomDataTemplate();
  useEffect(() => __async(this, null, function* () {
    var _a2, _b, _c, _d, _e;
    if (props.currentStep === 1 && !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      if ((_c = (_b = props.generationConfiguration) == null ? void 0 : _b.TemplateFileReference) == null ? void 0 : _c.TransactionDataFields) {
        const fields = (_e = (_d = props.generationConfiguration) == null ? void 0 : _d.TemplateFileReference) == null ? void 0 : _e.TransactionDataFields;
        setDragFields(
          fields.map((field) => ({
            id: field.Index,
            label: field.Name,
            info: field.Name,
            FieldType: "String",
            type: "TemplateDataSource"
          }))
        );
        setIsValidated(false);
      }
      if (props.generationConfiguration) {
        const metaData = yield props.getMetaDataConfiguration(props.loader, "AllInOne");
        setFieldMetadata(metaData);
      }
    }
  }), [props.generationConfiguration]);
  useEffect(() => {
    var _a2, _b, _c, _d, _e;
    if (props.currentStep === 1 && !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      if (((_b = props.generationConfiguration) == null ? void 0 : _b.CustomSourceConfiguration) && dragFields && fieldMetadata) {
        const mappings = getMappedPairs((_d = (_c = props.generationConfiguration) == null ? void 0 : _c.CustomSourceConfiguration) == null ? void 0 : _d.OneMapping);
        setPairs(mappings);
      } else if (fieldMetadata && !((_e = props.generationConfiguration) == null ? void 0 : _e.CustomSourceConfiguration)) {
        setPairsToDefault();
      }
    }
  }, [dragFields, fieldMetadata]);
  useEffect(() => {
    var _a2;
    if (props.currentStep === 1 && ((_a2 = props.generationConfiguration) == null ? void 0 : _a2.SalePurchaseSeparately)) {
      setPairsToDefault();
    }
  }, [(_a = props.generationConfiguration) == null ? void 0 : _a.SalePurchaseSeparately]);
  const fallBackFieldTemplate = (dropFieldName) => {
    var _a2;
    return ((_a2 = pairs == null ? void 0 : pairs.get(dropFieldName)) == null ? void 0 : _a2.fallBackField) ? fallBackPairTemplate(dropFieldName) : emptyFallBackFieldTemplate(dropFieldName);
  };
  const emptyFallBackFieldTemplate = (dropFieldName) => html`
		<dc-dropfield
			.class=${"w-36 px-4 py-2 text-center justify-center border-2 rounded-xl border-solid px-4 py-2 hover:border-dashed hover:border-gray-500 bg-white"}
			.emptyText=${`+ ${i18next.t("Add Fallback")}`}
			.allowedDragTypes=${["TemplateDataSource"]}
			.onDrop=${(e) => {
    var _a2, _b, _c;
    const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
    if (draggedItem.id === ((_b = (_a2 = pairs.get(dropFieldName)) == null ? void 0 : _a2.dragField) == null ? void 0 : _b.id)) {
      notifyWarning(i18next.t("You cannot set the same field as fallback."));
      return;
    }
    const newPairs = new Map(pairs);
    newPairs.set(dropFieldName, { dragField: (_c = pairs.get(dropFieldName)) == null ? void 0 : _c.dragField, fallBackField: draggedItem });
    setPairs(newPairs);
  }}
		></dc-dropfield>
	`;
  const fallBackPairTemplate = (dropFieldName) => html`
		<div class="w-36 flex gap-4 items-center">
			<div
				class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
				title=${pairs.get(dropFieldName).fallBackField.label}
			>
				<div class="truncate">${pairs.get(dropFieldName).fallBackField.label}</div>
				<span
					class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
					@click=${() => {
    var _a2;
    const newPairs = new Map(pairs);
    newPairs.set(dropFieldName, { dragField: (_a2 = pairs.get(dropFieldName)) == null ? void 0 : _a2.dragField, fallBackField: void 0 });
    setPairs(newPairs);
  }}
				>
					${closeIcon("fill-black w-3 h-3")}
				</span>
			</div>
		</div>
	`;
  const dropfieldTemplate = (dropFieldName, type) => {
    var _a2, _b, _c;
    return html`
			<div class=${classNames("flex items-center border shadow-lg rounded-xl px-6 py-4 gap-4")}>
				<div class="flex flex-col gap-1 basis-2/5">
					<span class="font-semibold">${TAX_DESK_DATA_LABELS[dropFieldName]}${isFieldRequired(dropFieldName) ? "*" : ""}</span><span>${type}</span>
				</div>
				${((_a2 = pairs.get(dropFieldName)) == null ? void 0 : _a2.dragField) ? html`
							<div class="basis-3/5 flex gap-4 items-center">
								<div
									class="flex justify-between gap-4 items-center w-60 truncate bg-gray-50 border-2 border-brand-tertiary border-solid rounded-xl px-4 py-2 font-semibold"
									title=${pairs.get(dropFieldName).dragField.label}
								>
									<div class="truncate">${pairs.get(dropFieldName).dragField.label}</div>
									<span
										class="rounded-full w-5 h-5 bg-gray-200 hover:bg-gray-300 flex flex-shrink-0 items-center justify-center cursor-pointer"
										@click=${() => {
      const newPairs = new Map(pairs);
      newPairs.set(dropFieldName, void 0);
      setPairs(newPairs);
    }}
									>
										${closeIcon("fill-black w-3 h-3")}
									</span>
								</div>
								${fallBackFieldTemplate(dropFieldName)}
							</div>
					  ` : html`
							<div class="basis-3/5 flex justify-end gap-4 items-center">
								<dc-dropfield
									.class=${classNames("px-4 w-60 py-2 text-center justify-center border-2 rounded-xl border-solid hover:border-dashed", {
      "border-red-500 bg-red-100": isValidated && !((_b = pairs.get(dropFieldName)) == null ? void 0 : _b.dragField) && isFieldRequired(dropFieldName),
      "hover:border-gray-500 bg-gray-200": !isValidated || ((_c = pairs.get(dropFieldName)) == null ? void 0 : _c.dragField) || !isFieldRequired(dropFieldName)
    })}
									.emptyText=${i18next.t("Drop data mapping here")}
									.allowedDragTypes=${["TemplateDataSource"]}
									.onDrop=${(e) => {
      var _a3;
      const draggedItem = JSON.parse(e.dataTransfer.getData("payload"));
      const newPairs = new Map(pairs);
      newPairs.set(dropFieldName, { dragField: draggedItem, fallBackField: (_a3 = pairs.get(dropFieldName)) == null ? void 0 : _a3.fallBackField });
      setPairs(newPairs);
    }}
								></dc-dropfield>
							</div>
					  `}
			</div>
		`;
  };
  const uploadTransactionDataButtonTemplate = () => {
    var _a2;
    return html`
		<dc-fileupload2
			class="self-start w-fit"
			.label=${i18next.t("Upload Sample Data File")}
			.withLoader=${isFileUploading}
			.loaderPosition=${"right"}
			.showFileLink=${false}
			.accept=${FormatTypeMapping.get((_a2 = props.generationConfiguration) == null ? void 0 : _a2.AvailableTemplates[0])}
			@upload=${(e) => __async(this, null, function* () {
      return handleUploadTransactionDataButtonClick(e);
    })}
		></dc-fileupload2>
	`;
  };
  const taxDeskDataTemplate = () => html`
		<div class="flex flex-col gap-2 w-full">
			<div class="text-xl font-bold">${i18next.t("To be mapped fields")}</div>
			<div>${i18next.t("Fields with * are mandatory to map, the rest of the fields are optional.")}</div>
			<div class="flex flex-col gap-4 w-full max-w-[750px]">
				${Array.from(pairs.keys()).map((dropFieldName) => {
    var _a2;
    return dropfieldTemplate(dropFieldName, (_a2 = fieldMetadata.find((field) => field.Name === dropFieldName)) == null ? void 0 : _a2.Type);
  })}
			</div>
		</div>
	`;
  const uploadedDataTemplate = () => (dragFields == null ? void 0 : dragFields.length) > 0 ? html`
					<div class="sticky top-20 flex flex-col gap-4 max-h-[600px] w-[340px] shadow-xl border rounded-l-xl px-8 py-6 overflow-y-auto font-semibold">
						${isEveryDragFieldSet() ? html`<div>${i18next.t("Congratulations! All data mapped successfully!")}</div>` : ""}
						${dragFields.map((field) => sharedCustomDataTemplate.draggableItemTemplate(field.label, field.info, field.id))}
					</div>
			  ` : "";
  const uploadSectionTemplate = () => {
    var _a2, _b, _c, _d, _e;
    return html`
		<div class="flex flex-col gap-2">
			<div class="text-xl font-bold">${i18next.t("Upload Sample Transaction Data File")}</div>
			<div class="flex flex-wrap gap-2 items-center">
				${uploadTransactionDataButtonTemplate()}
				<div class=${classNames("flex gap-2 items-center", { hidden: !((_a2 = props.generationConfiguration) == null ? void 0 : _a2.TemplateFileReference) })}>
					${fileIcon((_c = (_b = props.generationConfiguration) == null ? void 0 : _b.TemplateFileReference) == null ? void 0 : _c.FileName)}
					<div
						class="hover:underline cursor-pointer truncate"
						@click=${() => __async(this, null, function* () {
      const downloadFile = yield props.getTemplateFile("AllInOne", props.loader);
      simulateDownload(downloadFile);
    })}
					>
						${(_e = (_d = props.generationConfiguration) == null ? void 0 : _d.TemplateFileReference) == null ? void 0 : _e.FileName}
					</div>
				</div>
			</div>
		</div>
	`;
  };
  const dataMappingTemplate = () => html`
		<div class="flex gap-8 w-full">
			${taxDeskDataTemplate()}
			<div class="flex flex-col gap-2 w-full">${uploadSectionTemplate()} ${uploadedDataTemplate()}</div>
		</div>
	`;
  const mapAllRequiredFieldsErrorTemplate = () => !isEveryRequiredDropFieldSet() && isValidated ? html`<div class="text-red-500 font-semibold">${i18next.t("Please map all required fields at the To be mapped fields list.")}</div> ` : "";
  const template = () => html`
		<div class="m-8 flex flex-col gap-4 w-full">
			<div>${templateTitle(i18next.t("Field Configuration"))}</div>

			${dataMappingTemplate()}
			<div class="flex items-center flex-wrap gap-4">
				${SecondaryButton({ class: "w-32 my-6", text: i18next.t("Previous"), onClick: () => props.setStep(0) })}
				${PrimaryButton({
    class: "w-32 my-6",
    text: i18next.t("Next"),
    onClick: handleNextButtonClick
  })}
				${mapAllRequiredFieldsErrorTemplate()}
			</div>
		</div>
	`;
  return { template, uploadedFile };
}
