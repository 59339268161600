import { formatDateTimeUTC, parseDateTimeUTC } from "./dateHelper";
import { toSafeNumber } from "./numberHelper";
import { isEmpty } from "./stringHelper";
export const decodeGridState = (s) => {
  if (s === void 0) {
    return void 0;
  }
  const getVariableValue = (pVal) => {
    const type = pVal[0];
    const val = decodeURIComponent(pVal.slice(1));
    if (type === "u") {
      return void 0;
    } else if (type === "n") {
      return toSafeNumber(val);
    } else if (type === "d") {
      return parseDateTimeUTC(val).toDate();
    } else {
      return val;
    }
  };
  const parts = s.split("*");
  if (parts.length > 0) {
    const first = parts[0].split("|");
    if (first.length > 3) {
      const gs = {
        pageIndex: toSafeNumber(first[0]),
        pageSize: toSafeNumber(first[1]),
        orderBy: first[2],
        orderDir: first[3],
        appliedFilters: parts.slice(1).map((p) => {
          const afArr = p.split("|");
          const typeAlias = afArr[2];
          let selectedValues;
          if (!isEmpty(afArr[3])) {
            if (typeAlias === "s") {
              selectedValues = afArr[3];
            } else {
              selectedValues = afArr[3].split(",").map((s2) => decodeURIComponent(s2));
            }
          }
          const filterProps = {
            operator: afArr[1],
            selectedValues,
            min: getVariableValue(afArr[4]),
            max: getVariableValue(afArr[5])
          };
          const af = {
            field: afArr[0],
            filterProps
          };
          return af;
        })
      };
      return gs;
    }
    return void 0;
  }
  return void 0;
};
export const encodeGridState = (gridState) => {
  const pageAndOrder = `${gridState.pageIndex}|${gridState.pageSize}|${gridState.orderBy}|${gridState.orderDir}`;
  const getSelectedValues = (filter) => {
    if (filter.filterProps.selectedValues === void 0) {
      return "";
    }
    return (typeof filter.filterProps.selectedValues === "string" ? [filter.filterProps.selectedValues] : filter.filterProps.selectedValues).map((s) => encodeURIComponent(s)).join(",");
  };
  const getVariableValue = (val) => {
    if (val === void 0) {
      return "u";
    }
    if (typeof val === "number") {
      return "n" + encodeURIComponent(val.toString());
    } else if (val instanceof Date) {
      return "d" + encodeURIComponent(formatDateTimeUTC(val));
    }
    return "s" + encodeURIComponent(val.toString());
  };
  const getTypeAlias = (filter) => {
    return typeof filter.filterProps.selectedValues === "string" ? "s" : "a";
  };
  const filters = gridState.appliedFilters.map(
    (af) => `${af.field}|${af.filterProps.operator}|${getTypeAlias(af)}|${getSelectedValues(af)}|${getVariableValue(af.filterProps.min)}|${getVariableValue(af.filterProps.max)}`
  );
  return [pageAndOrder, ...filters].join("*");
};
export const getClientIdFromCurrentPath = (currentPathName) => {
  const pathParts = currentPathName.split("/");
  const clientIdIndex = pathParts.indexOf("clients") + 1;
  const clientId = Number(pathParts[clientIdIndex]);
  return isNaN(clientId) ? void 0 : clientId;
};
