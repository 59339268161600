var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import "./amazon-skus.scss";
import {
  getLocalizedValidationMessage,
  GTU_CODES,
  SupportedSkuCurrencyDataSource,
  SkuFieldNames
} from "./AmazonSkuModels";
import { useEffect, useLoadingReducer, useState } from "GlobalShared/haunted/CustomHooks";
import { extractFirstError, notifyError, notifyErrorWithWarning, notifySuccess, notifyWarning, withErrorHandling } from "GlobalShared/helpers/errorHelper";
import { toSafeNumber } from "GlobalShared/helpers/numberHelper";
import { removeIcon, templateTitle } from "GlobalShared/templates/commons";
import { mergeAppliedFilter } from "GlobalShared/ui/dc-table";
import i18next from "i18next";
import { html } from "lit-html";
import { isApiError } from "GlobalShared/common/ApiError";
import { useAwesomeValidator, Validation } from "GlobalShared/validation/AwesomeValidator";
import { useConfirmModal } from "../modals/commonModals";
import { ONLY_XLSX_ACCEPT_LIST } from "GlobalShared/helpers/fileHelper";
import { useMemo } from "haunted";
import { isVatRegistrationActiveAt } from "GlobalShared/helpers/servicesHelper";
import { isEmpty } from "GlobalShared/helpers/stringHelper";
import { SecondaryButton } from "GlobalShared/ui/SecondaryButton";
import { PrimaryButton } from "GlobalShared/ui/PrimaryButton";
import classNames from "classnames";
const DEFAULT_FILTERS = [];
const EXPORT_SKU_PAGE_SIZE = 1e5;
const SELECTABLE_YEARS_COUNT = 5;
const DEFAULT_SKU = {
  Id: void 0,
  SellerEntityId: void 0,
  SkuId: void 0,
  SelectedCommodities: [],
  ItemIdentifier: void 0,
  Price: void 0
};
export const useAmazonSkus = (props) => {
  const loadSkus = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const hasActiveIntraService = yield hasAnyActiveIntrastatService();
        const result = yield props.loadSkus(gridState, showOnlyMissing, hasActiveIntraService);
        setSearchResult(result);
      }),
      (error) => {
        setSearchResult({
          skus: void 0,
          total: 0
        });
        notifyErrorWithWarning(error);
      }
    );
  });
  const loadGTUCodes = () => __async(void 0, null, function* () {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        const gtuCodes2 = yield props.loadGtuCodes();
        setGtuCodes(
          gtuCodes2.map((gc) => ({
            value: gc.id,
            label: `${gc.id} - ${GTU_CODES[gc.id].length > 50 ? GTU_CODES[gc.id].substring(0, 50) + "..." : GTU_CODES[gc.id]}`
          }))
        );
      }),
      (error) => {
        notifyError(error);
      }
    );
  });
  const toSelectItem = (clientItem) => {
    const supplementaryUnit = clientItem.SupplementaryUnit ? ` - ${clientItem.SupplementaryUnit}` : "";
    return {
      value: clientItem.Code,
      label: `${clientItem.Code} - ${clientItem.Description}${supplementaryUnit}`
    };
  };
  const onSortChanged = (e) => {
    const newGridState = __spreadProps(__spreadValues({}, gridState), {
      orderBy: e.detail.orderBy,
      orderDir: e.detail.orderDir
    });
    setGridState(newGridState);
  };
  const onPageChanged = (e) => {
    setGridState(__spreadProps(__spreadValues({}, gridState), { pageIndex: e.detail.selectedPageIndex, pageSize: e.detail.selectedPageSize }));
  };
  const onFilterChanged = (e) => {
    const newGridState = __spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: mergeAppliedFilter(gridState.appliedFilters, e.detail)
    });
    setGridState(newGridState);
  };
  const editSku = (sku) => {
    setSkuEditMode(true);
    setCurrentSku(sku);
    setShowSkuModal(true);
  };
  const removeSku = (sku) => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.removeSku(sku.Id);
        yield loadSkus();
      }),
      (error) => {
        notifyErrorWithWarning(error);
      }
    );
  };
  const addSku = () => {
    setSkuEditMode(false);
    setCurrentSku(DEFAULT_SKU);
    setShowSkuModal(true);
  };
  const saveSku = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchLoading("inc");
        if (skuEditMode) {
          yield props.updateSku(currentSku);
        } else {
          yield props.addSku(currentSku);
        }
        yield loadSkus();
        notifySuccess(i18next.t("Saved successfully"));
        setShowSkuModal(false);
      }),
      (error) => {
        if (error.statusCode === 404) {
          const firstError = extractFirstError(error);
          if (!firstError) {
            notifyWarning(i18next.t("Invalid data"));
            return;
          }
          if (firstError.code === "SkuNotFound") {
            notifyWarning(
              i18next.t("Sku not found ({{id}})", {
                id: `sellerEntityId: ${currentSku.SellerEntityId}, skuId: ${currentSku.SkuId}`
              })
            );
          } else if (firstError.code === "CurrencyNotFound") {
            notifyWarning(
              i18next.t("Currency not found ({{id}})", {
                id: currentSku.Price.Currency
              })
            );
          } else {
            notifyWarning(firstError.message);
          }
        } else if (error.statusCode === 409) {
          notifyWarning(
            i18next.t("Sku already exists ({{id}})", {
              id: `sellerEntityId: ${currentSku.SellerEntityId}, skuId: ${currentSku.SkuId}`
            })
          );
        } else if (error.statusCode === 422) {
          const firstError = extractFirstError(error);
          if (firstError.code === "InvalidCommodityCode") {
            notifyWarning(`${i18next.t("Invalid commodity code")} (${firstError.message})`);
          }
        } else {
          notifyError(error);
        }
      },
      () => {
        dispatchLoading("dec");
      }
    );
  };
  const downloadSkus = () => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchLoading("inc");
        yield props.downloadSkus(EXPORT_SKU_PAGE_SIZE, gridState, showOnlyMissing);
      }),
      (error) => {
        notifyErrorWithWarning(error);
      },
      () => {
        dispatchLoading("dec");
      }
    );
  });
  const downloadCurrencies = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        yield props.downloadCurrencies();
      }),
      (error) => {
        notifyError(error);
      }
    );
  };
  const downloadCommodityCodes = () => {
    withErrorHandling(
      () => __async(void 0, null, function* () {
        dispatchDownloadingCommodityCodes("inc");
        yield props.downloadCommodityCodes();
      }),
      (error) => {
        notifyError(error);
      },
      () => {
        dispatchDownloadingCommodityCodes("dec");
      }
    );
  };
  const uploadSkus = (file) => __async(void 0, null, function* () {
    yield withErrorHandling(
      () => __async(void 0, null, function* () {
        setUploadProcessing(true);
        dispatchLoading("inc");
        const uploadResult = yield props.uploadSkus(file);
        if (uploadResult.validationMessages.length > 0) {
          setUploadValidationMessages(uploadResult.validationMessages.map(getLocalizedValidationMessage));
        } else if (!uploadResult.isSuccess) {
          setUploadValidationMessages([i18next.t("Wrong excel format")]);
        } else {
          setShowUploadModal(false);
          yield loadSkus();
          notifySuccess("File uploaded successfully");
        }
      }),
      (error) => {
        if (isApiError(error)) {
          const errorMessages = error.ErrorMessages.map((e) => {
            const splitMessage = e.Message.split(";");
            if (splitMessage.length === 3) {
              return {
                columnName: splitMessage[0],
                rowNumber: splitMessage[1],
                validationCode: splitMessage[2]
              };
            } else {
              return void 0;
            }
          });
          setUploadValidationMessages(errorMessages.filter((e) => e).map(getLocalizedValidationMessage));
        } else {
          notifyError(error);
        }
      },
      () => {
        setUploadProcessing(false);
        dispatchLoading("dec");
      }
    );
  });
  const addCommodity = () => {
    if (currentSku.SelectedCommodities === void 0) {
      addEmptyCommodity();
      return;
    }
    setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
      SelectedCommodities: [
        ...currentSku.SelectedCommodities,
        {
          Code: void 0,
          ValidFromYear: void 0
        }
      ]
    }));
  };
  const removeCommodity = (conditionIndex) => {
    setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
      SelectedCommodities: currentSku.SelectedCommodities.filter((_, i) => i !== conditionIndex)
    }));
  };
  const updateYearAndClearCodeAtIndex = (index, newValue) => {
    setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
      SelectedCommodities: currentSku.SelectedCommodities.map((c, i) => {
        if (i === index) {
          return { ValidFromYear: newValue, Code: void 0 };
        }
        return c;
      })
    }));
  };
  const updateCommodityCodeAtIndex = (commodityIndex, newValue) => {
    setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
      SelectedCommodities: currentSku.SelectedCommodities.map((c, i) => {
        if (i === commodityIndex) {
          return __spreadProps(__spreadValues({}, c), { Code: newValue });
        }
        return c;
      })
    }));
  };
  const getUniqueSelectableYears = (currentYearSelected) => commodityCodeFields.filter((obj) => {
    var _a;
    return (_a = currentSku.SelectedCommodities) == null ? void 0 : _a.every((commodity) => commodity.ValidFromYear !== obj.year || commodity.ValidFromYear === currentYearSelected);
  }).map((obj) => obj.year);
  const getSortedUniqueSelectableCodes = (currentCommodity, filterText) => {
    if (currentCommodity.ValidFromYear === void 0) {
      return [];
    }
    const filteredCommodities = commodities.get(currentCommodity.ValidFromYear).map((c) => toSelectItem(c)).filter((c) => {
      var _a;
      const isAvailable = (_a = currentSku.SelectedCommodities) == null ? void 0 : _a.every((commodity) => commodity.Code !== c.value || commodity.Code === currentCommodity.Code);
      if (isEmpty(filterText)) {
        return isAvailable;
      }
      return c.label.toLowerCase().includes(filterText.toLowerCase()) && isAvailable;
    });
    const sortedFilteredCommodities = sortItemsByFilterTextIndex(filteredCommodities, filterText);
    return sortedFilteredCommodities;
  };
  const isFirstAndOnlyCommodityRow = (index) => index === 0 && currentSku.SelectedCommodities.length === 1;
  const isActiveIntrastatIn = (cs, currentDate) => {
    var _a, _b, _c;
    return cs.ComplianceSubscriptionStatus === "Active" && ((_a = cs.IntrastatIn) == null ? void 0 : _a.StartDate) <= currentDate && currentDate <= ((_b = cs.IntrastatIn) == null ? void 0 : _b.EndDate) || cs.ComplianceSubscriptionStatus === "Cancelled" && ((_c = cs.IntrastatIn) == null ? void 0 : _c.StartDate) <= currentDate && currentDate < cs.IntrastatIn.CancellationDate;
  };
  const isActiveIntrastatOut = (cs, currentDate) => {
    var _a, _b, _c;
    return cs.ComplianceSubscriptionStatus === "Active" && ((_a = cs.IntrastatOut) == null ? void 0 : _a.StartDate) <= currentDate && currentDate <= ((_b = cs.IntrastatOut) == null ? void 0 : _b.EndDate) || cs.ComplianceSubscriptionStatus === "Cancelled" && ((_c = cs.IntrastatOut) == null ? void 0 : _c.StartDate) <= currentDate && currentDate < cs.IntrastatOut.CancellationDate;
  };
  const addEmptyCommodity = () => {
    setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
      SelectedCommodities: [
        {
          Code: void 0,
          ValidFromYear: void 0
        }
      ]
    }));
  };
  const sortItemsByFilterTextIndex = (items, filterText) => {
    if (isEmpty(filterText)) {
      return items;
    }
    const itemsDeepCopy = structuredClone(items);
    return itemsDeepCopy.sort((a, b) => {
      const aIndex = a.label.toLowerCase().indexOf(filterText.toLowerCase());
      const bIndex = b.label.toLowerCase().indexOf(filterText.toLowerCase());
      if (aIndex === bIndex) {
        return a.label.localeCompare(b.label);
      }
      return aIndex - bIndex;
    });
  };
  const [loading, dispatchLoading] = useLoadingReducer();
  const [downloadingCommodityCodes, dispatchDownloadingCommodityCodes] = useLoadingReducer();
  const [searchResult, setSearchResult] = useState(void 0);
  const [gtuCodes, setGtuCodes] = useState(void 0);
  const [gridState, setGridState] = useState({
    pageIndex: 0,
    appliedFilters: DEFAULT_FILTERS,
    pageSize: 20,
    orderBy: "SkuId",
    orderDir: "asc"
  });
  const confirmModal = useConfirmModal();
  const [showOnlyMissing, setShowOnlyMissing] = useState(true);
  const [currentSku, setCurrentSku] = useState(void 0);
  const [showSkuModal, setShowSkuModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [uploadValidationMessages, setUploadValidationMessages] = useState([]);
  const [skuEditMode, setSkuEditMode] = useState(false);
  const [hasActiveIntrastatService, setHasActiveIntrastatService] = useState(void 0);
  const skuValidator = useAwesomeValidator(
    [
      Validation.ruleFor("SkuId", (vm) => vm.SkuId).isRequired(),
      Validation.ruleFor("SellerEntityId", (vm) => vm.SellerEntityId).isRequired().when(() => !props.isCustomSource),
      Validation.ruleFor("Price", (vm) => {
        var _a;
        return (_a = vm.Price) == null ? void 0 : _a.Value;
      }).isRequired(i18next.t("Number required")).when(() => !props.isCustomSource),
      Validation.ruleFor("Currency", (vm) => {
        var _a;
        return (_a = vm.Price) == null ? void 0 : _a.Currency;
      }).isRequired().hasLength(3).when(() => !props.isCustomSource),
      Validation.ruleFor("selectedCommodities.0.ValidFromYear", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(0)) == null ? void 0 : _a.ValidFromYear;
      }).isRequired().when((vm) => vm.SelectedCommodities.length > 0 || hasActiveIntrastatService),
      Validation.ruleFor("selectedCommodities.0.Code", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(0)) == null ? void 0 : _a.Code;
      }).isRequired().when((vm) => {
        var _a;
        return vm.SelectedCommodities.length > 0 && ((_a = vm.SelectedCommodities.at(0)) == null ? void 0 : _a.ValidFromYear) !== void 0;
      }),
      Validation.ruleFor("selectedCommodities.1.ValidFromYear", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(1)) == null ? void 0 : _a.ValidFromYear;
      }).isRequired().when((vm) => vm.SelectedCommodities.length > 1),
      Validation.ruleFor("selectedCommodities.1.Code", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(1)) == null ? void 0 : _a.Code;
      }).isRequired().when((vm) => {
        var _a;
        return vm.SelectedCommodities.length > 1 && ((_a = vm.SelectedCommodities.at(1)) == null ? void 0 : _a.ValidFromYear) !== void 0;
      }),
      Validation.ruleFor("selectedCommodities.2.ValidFromYear", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(2)) == null ? void 0 : _a.ValidFromYear;
      }).isRequired().when((vm) => vm.SelectedCommodities.length > 2),
      Validation.ruleFor("selectedCommodities.2.Code", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(2)) == null ? void 0 : _a.Code;
      }).isRequired().when((vm) => {
        var _a;
        return vm.SelectedCommodities.length > 2 && ((_a = vm.SelectedCommodities.at(2)) == null ? void 0 : _a.ValidFromYear) !== void 0;
      }),
      Validation.ruleFor("selectedCommodities.3.ValidFromYear", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(3)) == null ? void 0 : _a.ValidFromYear;
      }).isRequired().when((vm) => vm.SelectedCommodities.length > 3),
      Validation.ruleFor("selectedCommodities.3.Code", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(3)) == null ? void 0 : _a.Code;
      }).isRequired().when((vm) => {
        var _a;
        return vm.SelectedCommodities.length > 3 && ((_a = vm.SelectedCommodities.at(3)) == null ? void 0 : _a.ValidFromYear) !== void 0;
      }),
      Validation.ruleFor("selectedCommodities.4.ValidFromYear", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(4)) == null ? void 0 : _a.ValidFromYear;
      }).isRequired().when((vm) => vm.SelectedCommodities.length > 4),
      Validation.ruleFor("selectedCommodities.4.Code", (vm) => {
        var _a;
        return (_a = vm.SelectedCommodities.at(4)) == null ? void 0 : _a.Code;
      }).isRequired().when((vm) => {
        var _a;
        return vm.SelectedCommodities.length > 4 && ((_a = vm.SelectedCommodities.at(4)) == null ? void 0 : _a.ValidFromYear) !== void 0;
      })
    ],
    () => saveSku()
  );
  const commodityCodeFields = useMemo(() => {
    return Array.from({ length: SELECTABLE_YEARS_COUNT }, (_, i) => ({
      year: (/* @__PURE__ */ new Date()).getFullYear() - i,
      fieldName: `CommodityCode${SELECTABLE_YEARS_COUNT - i - 1}`
    })).sort((a, b) => a.year - b.year);
  }, []);
  const [commodities, setCommodities] = useState(/* @__PURE__ */ new Map());
  useEffect(() => {
    const fetchCommodities = () => __async(void 0, null, function* () {
      const allCommoditiesByYear = yield Promise.all(
        commodityCodeFields.map((cc) => __async(void 0, null, function* () {
          const result = yield props.getCommodityCodes(cc.year);
          return result.data.at(0);
        }))
      );
      const commodityMap = /* @__PURE__ */ new Map();
      allCommoditiesByYear.forEach((commoditiesByYear, index) => {
        commodityMap.set(commodityCodeFields[index].year, commoditiesByYear.Codes);
      });
      setCommodities(commodityMap);
    });
    fetchCommodities();
  }, []);
  const hasAnyActiveIntrastatService = () => __async(void 0, null, function* () {
    const currentDate = /* @__PURE__ */ new Date();
    const companyDetails = yield props.getCompanyDetails();
    const activeIntrastatSubs = companyDetails == null ? void 0 : companyDetails.CountryServices.filter((cs) => isActiveIntrastatIn(cs, currentDate) || isActiveIntrastatOut(cs, currentDate));
    const hasActiveIntrastatWithVatReg = companyDetails.VatRegistrations.some(
      (vatReg) => isVatRegistrationActiveAt(vatReg, currentDate) && activeIntrastatSubs.some((cs) => cs.Country.Code === vatReg.ServiceCountry.Code)
    );
    return hasActiveIntrastatWithVatReg;
  });
  const init = () => __async(void 0, null, function* () {
    setHasActiveIntrastatService(yield hasAnyActiveIntrastatService());
    yield loadGTUCodes();
  });
  useEffect(init, []);
  useEffect(loadSkus, [gridState, showOnlyMissing]);
  useEffect(() => {
    if (showSkuModal) {
      skuValidator.clear();
    }
  }, [showSkuModal]);
  useEffect(() => {
    if (showUploadModal) {
      setUploadValidationMessages([]);
    }
  }, [showUploadModal]);
  useEffect(() => {
    var _a;
    if (hasActiveIntrastatService && ((_a = currentSku == null ? void 0 : currentSku.SelectedCommodities) == null ? void 0 : _a.length) === 0) {
      addEmptyCommodity();
    }
    if (currentSku !== void 0) {
      skuValidator.validate(currentSku);
    }
  }, [currentSku]);
  const htmlUploadModal = () => {
    return html` <dc-modal
			.visible=${showUploadModal}
			@close=${() => setShowUploadModal(false)}
			.header=${i18next.t("Upload SKUs")}
			.content=${html`
				<div class="w-full h-full">
					<div class="md:grid md:grid-cols-2 md:gap-4">
						<div>
							<dc-fileupload
								.label=${i18next.t("Upload Skus")}
								.accept=${ONLY_XLSX_ACCEPT_LIST}
								@upload=${(event) => {
      const uploadedFile = event.detail.files[0];
      if (uploadedFile) {
        uploadSkus(uploadedFile);
      }
    }}
							></dc-fileupload>
						</div>
						<div>
							<div>${uploadValidationMessages == null ? void 0 : uploadValidationMessages.map((msg) => html` <div class="my-2 text-red-400">${msg}</div>`)}</div>
						</div>
						<div>${uploadProcessing ? `${i18next.t("Processing")}...` : ""}</div>
					</div>
					<div>
						<div class="mt-8 flex space-x-4">
							${PrimaryButton({
      class: "w-28",
      onClick: () => setShowUploadModal(false),
      text: i18next.t("Cancel")
    })}
						</div>
					</div>
				</div>
			`}
		>
		</dc-modal>`;
  };
  const commoditiesTemplate = () => {
    var _a, _b;
    return ((_a = currentSku.SelectedCommodities) == null ? void 0 : _a.length) > 0 ? (_b = currentSku.SelectedCommodities) == null ? void 0 : _b.map(
      (c, index) => {
        var _a2;
        return html`
						<div class="flex gap-4 mb-8 flex-wrap ${((_a2 = currentSku.SelectedCommodities) == null ? void 0 : _a2.length) > 1 && index > 0 ? "border-t-brand-primary border-t-2 pt-8" : ""}">
							<dc-select
								class="w-32"
								.label=${i18next.t("Year (valid from)")}
								.dataSource=${getUniqueSelectableYears(c.ValidFromYear)}
								.selectedValues=${c.ValidFromYear}
								.validationMessage=${skuValidator.getMessage(`selectedCommodities.${index}.ValidFromYear`)}
								@change=${(e) => {
          skuValidator.showMessageFor(`selectedCommodities.${index}.ValidFromYear`);
          const newVal = Number(e.detail.selectedValue);
          updateYearAndClearCodeAtIndex(index, newVal);
        }}
							></dc-select>
							<dc-select
								class="grow"
								.class=${"text-ellipsis"}
								.customSelectItemsClass=${"w-64 item-title"}
								.showItemTitle=${true}
								.label=${i18next.t("Code")}
								.dataSource=${(filterText) => getSortedUniqueSelectableCodes(c, filterText)}
								.selectedValues=${c.Code}
								.validationMessage=${skuValidator.getMessage(`selectedCommodities.${index}.Code`)}
								.readonly=${c.ValidFromYear === void 0}
								.filterable=${true}
								.filterProps=${{ ignoreCase: true, type: "includes" }}
								@change=${(e) => {
          skuValidator.showMessageFor(`selectedCommodities.${index}.Code`);
          updateCommodityCodeAtIndex(index, e.detail.selectedValue);
        }}
							></dc-select>
							${isFirstAndOnlyCommodityRow(index) && hasActiveIntrastatService ? "" : html` <button class="btn btn-primary mt-4" @click=${() => removeCommodity(index)}>Remove</button>`}
						</div>
					`;
      }
    ) : "";
  };
  const htmlSkuModal = () => {
    var _a, _b, _c;
    return html` <dc-modal
			.visible=${showSkuModal}
			@close=${() => setShowSkuModal(false)}
			.header=${skuEditMode ? props.isCustomSource ? i18next.t("Edit Product Identifier") : i18next.t("Edit SKU") : props.isCustomSource ? i18next.t("Add Product Identifier") : i18next.t("Add SKU")}
			.content=${currentSku === void 0 ? "" : html`
						<div class="w-full pb-8">
							<div class="flex flex-col gap-4">
								<div class="grid grid-cols-1 md:grid-cols-2 gap-4">
									<dc-input
										.label=${props.isCustomSource ? i18next.t("Product Identifier") : i18next.t("SkuId")}
										.value=${currentSku.SkuId}
										.validationMessage=${skuValidator.getMessage("SkuId")}
										.readonly=${skuEditMode}
										@change=${(e) => {
      skuValidator.showMessageFor("SkuId");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { SkuId: e.detail.value }));
    }}
									></dc-input>
									<dc-input
										class=${classNames({ hidden: props.isCustomSource })}
										.label=${i18next.t("Seller account")}
										.value=${currentSku.SellerEntityId}
										.validationMessage=${skuValidator.getMessage("SellerEntityId")}
										.readonly=${skuEditMode}
										@change=${(e) => {
      skuValidator.showMessageFor("SellerEntityId");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { SellerEntityId: e.detail.value }));
    }}
									></dc-input>

									<dc-select
										.label=${i18next.t("Item identifier")}
										.dataSource=${gtuCodes}
										.selectedValues=${currentSku.ItemIdentifier}
										.validationMessage=${skuValidator.getMessage("ItemIdentifier")}
										@change=${(e) => {
      skuValidator.showMessageFor("ItemIdentifier");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), { ItemIdentifier: e.detail.selectedValue }));
    }}
									></dc-select>
									<div class=${classNames("flex", { hidden: props.isCustomSource })}>
										<dc-input
											.label=${i18next.t("Price")}
											.value=${(_a = currentSku.Price) == null ? void 0 : _a.Value}
											.validationMessage=${skuValidator.getMessage("Price")}
											@change=${(e) => {
      skuValidator.showMessageFor("Price");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
        Price: __spreadProps(__spreadValues({}, currentSku.Price), {
          Value: toSafeNumber(e.detail.value)
        })
      }));
    }}
										></dc-input>
										<div class="ml-5">
											<dc-select
												.label=${i18next.t("Currency")}
												.dataSource=${SupportedSkuCurrencyDataSource}
												.selectedValues=${(_b = currentSku.Price) == null ? void 0 : _b.Currency}
												.filterable=${true}
												.validationMessage=${skuValidator.getMessage("Currency")}
												@change=${(e) => {
      skuValidator.showMessageFor("Currency");
      setCurrentSku(__spreadProps(__spreadValues({}, currentSku), {
        Price: __spreadProps(__spreadValues({}, currentSku.Price), {
          Currency: e.detail.selectedValue
        })
      }));
    }}
											></dc-select>
										</div>
									</div>
								</div>

								<div class="col-span-2 border-2 border-black rounded-sm p-4">
									<div class="text-lg font-bold mb-4">${i18next.t("Commodity code")}</div>
									${commoditiesTemplate()}
									${currentSku.SelectedCommodities === void 0 || ((_c = currentSku.SelectedCommodities) == null ? void 0 : _c.length) < (commodityCodeFields == null ? void 0 : commodityCodeFields.length) ? PrimaryButton({ class: "w-14", onClick: addCommodity, text: "+" }) : ""}
								</div>
							</div>
							<div class="mt-8 flex space-x-4">
								${SecondaryButton({
      class: "w-28",
      onClick: () => setShowSkuModal(false),
      text: i18next.t("Cancel")
    })}
								${PrimaryButton({
      class: "w-28",
      onClick: () => skuValidator.validateAndDispatch(currentSku),
      text: i18next.t("Save")
    })}
							</div>
						</div>
				  `}
		>
		</dc-modal>`;
  };
  const htmlGrid = () => {
    var _a;
    const columns = [
      {
        field: SkuFieldNames.SkuId,
        label: props.isCustomSource ? i18next.t("Product Identifier") : i18next.t("Sku"),
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      }
    ].concat(
      props.isCustomSource ? [] : {
        field: SkuFieldNames.SellerEntityId,
        label: i18next.t("Seller account"),
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      }
    ).concat([
      {
        field: SkuFieldNames.CommodityCode0,
        label: `${commodityCodeFields[0].year} ${i18next.t("Commodity code")}`,
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.CommodityCode1,
        label: `${commodityCodeFields[1].year} ${i18next.t("Commodity code")}`,
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.CommodityCode2,
        label: `${commodityCodeFields[2].year} ${i18next.t("Commodity code")}`,
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.CommodityCode3,
        label: `${commodityCodeFields[3].year} ${i18next.t("Commodity code")}`,
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.CommodityCode4,
        label: `${commodityCodeFields[4].year} ${i18next.t("Commodity code")}`,
        sortable: true,
        filterable: true,
        filterDescriptor: { type: "string" },
        columnType: "string"
      },
      {
        field: SkuFieldNames.ItemIdentifier,
        label: i18next.t("Item identifier"),
        sortable: true,
        filterable: true,
        filterDescriptor: {
          type: "select",
          multiSelect: true,
          dataSource: gtuCodes
        },
        columnType: "enum"
      }
    ]).concat(
      props.isCustomSource ? [] : [
        {
          field: SkuFieldNames.Price,
          label: i18next.t("Price"),
          sortable: true,
          filterable: true,
          filterDescriptor: { from: true, to: true, type: "number" },
          columnType: "number"
        }
      ]
    ).concat([
      {
        field: SkuFieldNames.Actions,
        label: ""
      }
    ]);
    const vm = {
      columns,
      data: searchResult.skus,
      cellTemplate: (index, field) => {
        var _a2, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o;
        const item = searchResult.skus[index];
        if (field === SkuFieldNames.SkuId) {
          return item.SkuId;
        } else if (field === SkuFieldNames.SellerEntityId) {
          return item.SellerEntityId;
        } else if (field === SkuFieldNames.CommodityCode0) {
          return (_c = (_b = (_a2 = item.SelectedCommodities) == null ? void 0 : _a2.find((c) => commodityCodeFields.find((com) => c.ValidFromYear === com.year && SkuFieldNames.CommodityCode0 === com.fieldName))) == null ? void 0 : _b.Code) != null ? _c : "";
        } else if (field === SkuFieldNames.CommodityCode1) {
          return (_f = (_e = (_d = item.SelectedCommodities) == null ? void 0 : _d.find((c) => commodityCodeFields.find((com) => c.ValidFromYear === com.year && SkuFieldNames.CommodityCode1 === com.fieldName))) == null ? void 0 : _e.Code) != null ? _f : "";
        } else if (field === SkuFieldNames.CommodityCode2) {
          return (_i = (_h = (_g = item.SelectedCommodities) == null ? void 0 : _g.find((c) => commodityCodeFields.find((com) => c.ValidFromYear === com.year && SkuFieldNames.CommodityCode2 === com.fieldName))) == null ? void 0 : _h.Code) != null ? _i : "";
        } else if (field === SkuFieldNames.CommodityCode3) {
          return (_l = (_k = (_j = item.SelectedCommodities) == null ? void 0 : _j.find((c) => commodityCodeFields.find((com) => c.ValidFromYear === com.year && SkuFieldNames.CommodityCode3 === com.fieldName))) == null ? void 0 : _k.Code) != null ? _l : "";
        } else if (field === SkuFieldNames.CommodityCode4) {
          return (_o = (_n = (_m = item.SelectedCommodities) == null ? void 0 : _m.find((c) => commodityCodeFields.find((com) => c.ValidFromYear === com.year && SkuFieldNames.CommodityCode4 === com.fieldName))) == null ? void 0 : _n.Code) != null ? _o : "";
        } else if (field === SkuFieldNames.ItemIdentifier) {
          return item.ItemIdentifier;
        } else if (field === SkuFieldNames.Price) {
          return item.Price ? `${item.Price.Value} ${item.Price.Currency}` : "";
        } else {
          return html` <div class="flex space-x-2 ${props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify") ? "" : "hidden"}">
						<dc-edit-button @click=${() => editSku(item)}></dc-edit-button>
						<span
							class="remove-icon-container"
							@click=${() => __async(void 0, null, function* () {
            if (yield confirmModal.confirm(i18next.t("Are you sure to delete?"))) {
              removeSku(item);
            }
          })}
						>
							${removeIcon()}
						</span>
					</div>`;
        }
      },
      appliedFilters: gridState.appliedFilters,
      sorting: {
        orderBy: gridState.orderBy,
        orderDir: gridState.orderDir
      },
      paging: {
        pageable: true,
        pageIndex: gridState.pageIndex,
        pageSize: gridState.pageSize,
        buttonCount: 10,
        pageSizes: [20, 50, 100],
        itemCount: searchResult.total
      }
    };
    return html` <div>
				<div>${templateTitle(props.isCustomSource ? i18next.t("Product Informamtion") : i18next.t("Amazon SKUs"))}</div>
				<div class="flex gap-4 mb-4 items-center my-4 flex-wrap">
					<button class="btn btn-primary" ?disabled=${!props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify")} @click=${addSku}>${i18next.t("Add")}</button>
					<button class="btn btn-primary" @click=${downloadSkus}>${props.isCustomSource ? i18next.t("Download Product list") : i18next.t("Download SKUs")}</button>
					<button class="btn btn-primary" ?disabled=${!props.permissionManager.hasPermission("Company_Amazon_SKUs_Modify")} @click=${() => setShowUploadModal(true)}>
						${props.isCustomSource ? i18next.t("Upload Product list") : i18next.t("Upload SKUs")}
					</button>
					<button class="btn btn-primary" @click=${downloadCurrencies}>${i18next.t("Download Currencies")}</button>
					<button class="btn btn-primary" @click=${downloadCommodityCodes}>
						${downloadingCommodityCodes.count > 0 ? i18next.t("Downloading...") : i18next.t("Download Commodity codes")}
					</button>
					${((_a = gridState.appliedFilters) == null ? void 0 : _a.length) > 0 ? html`
								<div>
									<button
										class="btn btn-primary"
										@click=${() => setGridState(__spreadProps(__spreadValues({}, gridState), {
      pageIndex: 0,
      appliedFilters: DEFAULT_FILTERS
    }))}
									>
										Reset filters
									</button>
								</div>
						  ` : ""}
					<dc-checkbox2
						class=${classNames({ hidden: props.isCustomSource })}
						.checked=${showOnlyMissing}
						.label=${i18next.t("Show only missing data")}
						@change=${(e) => {
      setShowOnlyMissing(e.detail.checked);
    }}
					></dc-checkbox2>
				</div>
			</div>
			<dc-table .vm=${vm} @onPageChange=${onPageChanged} @onSortChange=${onSortChanged} @onFilterChange=${onFilterChanged}></dc-table>`;
  };
  const mainTemplate = html` <div class="my-2 mb-16 mt-8 flex-row justify-between">
		<div>${searchResult ? htmlGrid() : "Loading..."}</div>
		${htmlSkuModal()}${htmlUploadModal()}${confirmModal.mainTemplate()}${loading.count > 0 ? html` <dc-loader></dc-loader>` : ""}
	</div>`;
  return { mainTemplate, commodityCodeFields };
};
